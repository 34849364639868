import React, { useContext } from 'react'
import { AppContext } from './../App'
import Button from '@cloudscape-design/components/button'

export function RadButton ({ disabled, formAction, ...rest }) {
  const { loadingCount, connected } = useContext(AppContext)

  return (
    <Button
      disabled={loadingCount > 0 || disabled}
      loading={!connected}
      formAction={formAction ?? 'none'}
      {...rest}
    />
  )
}
