import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadLink } from '../common/RadLink'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'

export function SiteDetail () {
  const navigate = useNavigate()
  const { siteId } = useParams()
  const { data: site } = useGet(`/api/site/${siteId}`)
  const confirmDelete = useConfirm('Delete site?', 'Delete site permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/site/${siteId}`, () => { navigate('/site') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (site) {
    return (
      <RadAppLayout
        name={site.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={site.description}
          >
            {site.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Parent Organization
                  </RadBox>
                  {site.parent && <RadLink href={`/organization/${site.parent.id}`}>{site.parent.name}</RadLink>}
                  {!site.parent && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Type
                  </RadBox>
                  <div>{site.type?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Address
                  </RadBox>
                  <div>{site.address?.line1}</div>
                  {site.address?.line2 && <div>{site.address?.line2}</div>}
                  <div>{site.address?.city} {site.address?.state} {site.address?.zip}</div>
                  {!site.addressId && '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phone
                  </RadBox>
                  <div>{site.phone ?? '-'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
