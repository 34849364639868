import React, { useContext } from 'react'
import TextFilter from '@cloudscape-design/components/text-filter'
import { AppContext } from '../App'

export function RadTextFilter ({ ...rest }) {
  const { connected } = useContext(AppContext)

  return (
    <TextFilter
      disabled={!connected || rest.disabled}
      {...rest}
    />
  )
}
