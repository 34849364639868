import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAlert } from '../common/RadAlert'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'

export function GlobalNotificationsSettings () {
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const { data: users, count } = useGet('/api/user')
  const { data: roles } = useGet('/api/role')
  const [formValues, setFormValues] = useState({ consecutiveAbsencesNotificationEnabled: false })
  const [selectedItems, setSelectedItems] = useState([])
  const [success, setSuccess] = useState(false)

  function getSelectedUserIds () {
    let selectedUsers = []
    if (selectedItems.length > 0) {
      if (selectedItems[0].type === 'role') {
        selectedUsers = users.filter(x => x.roles.find(y => y.id === selectedItems[0].id))
      } else {
        selectedUsers = [users.find(x => x.id === selectedItems[0].id)]
      }
    }
    return selectedUsers.map(x => x.id)
  }

  const update = usePut(
    '/api/user-preferences/override',
    { ...formValues, userIds: getSelectedUserIds() },
    () => {
      setSelectedItems([])
      setSuccess(true)
    }
  )

  if (users != null && roles != null) {
    const items =
    users.map(x => ({ key: 'user-' + x.id, id: x.id, name: x.name, type: 'user' }))
      .concat(roles.map(x => ({ key: 'role-' + x.id, id: x.id, name: x.name + ' Role', type: 'role' })))
      .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>Global Notifications Settings</RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadTable
              header={
                <RadHeader>
                  Roles / Users
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => e.name
                }
              ]}
              trackBy='key'
              selectionType='single'
              onSelectionChange={({ detail }) => {
                setSelectedItems(detail.selectedItems)
                setSuccess(false)
              }}
              selectedItems={selectedItems}
              items={items.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase())).slice((currentPageIndex - 1) * pageLength, currentPageIndex * pageLength)}
              filter={
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search students'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                    setSelectedItems([])
                  }}
                />
              }
              pagination={
                <RadPagination
                  currentPageIndex={currentPageIndex}
                  pagesCount={Math.ceil(count / pageLength)}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
            }
            />
            {selectedItems.length > 0 &&
              <form onSubmit={e => e.preventDefault()}>
                <RadForm
                  actions={
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadButton
                        formAction='submit'
                        variant='primary'
                        disabled={selectedItems.length === 0}
                        onClick={() => {
                          setSuccess(false)
                          update()
                        }}
                      >
                        Apply Changes to {selectedItems[0].name}
                      </RadButton>
                    </RadSpaceBetween>
                }
                >
                  <RadContainer
                    header={
                      <RadHeader
                        variant='h2'
                      >
                        Consecutive Absences Notification
                      </RadHeader>
                  }
                  >
                    <RadSpaceBetween size='l'>
                      <RadFormField label='Number of consecutive absences' field='consecutiveAbsencesNotificationMinimum'>
                        <RadInput
                          ariaRequired
                          placeholder='Enter number of consecutive absences'
                          value={formValues.consecutiveAbsencesNotificationMinimum}
                          onChange={({ detail }) => setFormValues({ ...formValues, consecutiveAbsencesNotificationMinimum: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Enabled' field='consecutiveAbsencesNotificationEnabled'>
                        <RadCheckbox
                          checked={formValues.consecutiveAbsencesNotificationEnabled}
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, consecutiveAbsencesNotificationEnabled: detail.checked })
                          }}
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                  </RadContainer>
                </RadForm>
              </form>}
            {success && <RadAlert dismissible statusIconAriaLabel='Success' type='success'>Changes applied successfully</RadAlert>}
          </RadSpaceBetween>
        }
      />
    )
  }
}
