import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadButton } from '../common/RadButton'

export function UserEdit () {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { data: user } = useGet(userId ? `/api/user/${userId}` : null)
  const [formValues, setFormValues] = useState()
  const create = usePost('/api/user', formValues, (resp) => { navigate(`/user/${resp.id}`) })
  const update = usePut(`/api/user/${userId}`, formValues, () => { navigate(`/user/${userId}`) })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(user ?? defaultFormValues)
  }, [user])

  async function cancel () {
    if (userId) {
      navigate(`/user/${userId}`)
    } else {
      navigate('/user')
    }
  }

  async function saveChanges () {
    if (userId) { update() } else { create() }
  }

  if (formValues) {
    const breadcrumbGroupItems = [
      { text: 'Home', href: '/' },
      { text: 'Users', href: '/user' }
    ]
    if (formValues.id) {
      breadcrumbGroupItems.push({ text: formValues.name, href: `/user/${formValues.id}` })
      breadcrumbGroupItems.push({ text: 'Edit' })
    } else {
      breadcrumbGroupItems.push({ text: 'Create' })
    }

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit User' : 'New User'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer>
                <RadSpaceBetween size='l'>
                  <RadFormField label='Full Name' field='name'>
                    <RadInput
                      ariaRequired
                      placeholder='Enter full name'
                      disabled={formValues.id}
                      value={formValues.name ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Email' field='email'>
                    <RadInput
                      ariaRequired
                      type='email'
                      inputMode='email'
                      placeholder='Enter email address'
                      disabled={formValues.id}
                      value={formValues.email ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Title' field='title'>
                    <RadInput
                      placeholder='Enter title'
                      value={formValues.title ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, title: detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Phone' field='phone'>
                    <RadInput
                      inputMode='tel'
                      placeholder='Enter phone'
                      value={formValues.phone ?? ''}
                      pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                      onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                    />
                  </RadFormField>
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
