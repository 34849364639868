import { useState, useEffect, useContext } from 'react'
import { AppContext } from './../App'

export function useGet (uri, silent = false) {
  const { user, loadingCount, setLoadingCount, notFound, setNotFound, setPermissionDenied, reloadCounter, error, setError, setConnected } = useContext(AppContext)
  const [data, setData] = useState(null)
  const [count, setCount] = useState(null)

  useEffect(() => {
    (async () => {
      const loading = (expr) => {
        if (!silent) setLoadingCount(expr)
      }

      if (uri) {
        loading(count => count + 1)
        const token = await user.getIdToken()
        const response = await fetch(uri, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }).catch(() => {
          setConnected(false)
          return { status: 408 }
        })
        let text
        let json
        switch (response.status) {
          case 200:
            try {
              json = await response.json()
              setData(json)
              setConnected(true)
            } catch { }
            setCount(response.headers.get('count'))
            if (!silent) {
              setNotFound(false)
              setError(null)
              setPermissionDenied(null)
            }
            break
          case 403:
            text = await response.text()
            setPermissionDenied(text)
            setNotFound(false)
            setData(null)
            break
          case 404:
            setNotFound(true)
            setData(null)
            break
          case 408:
            setConnected(false)
            setError({ code: 'Network Error', message: 'Please check your internet connection.' })
            break
          case 500:
            setError({ code: response.statusText })
            setNotFound(false)
            setPermissionDenied(null)
            break
          default:
        }
        loading(count => count - 1)
      } else {
        setData(null)
      }
    })()
  }, [uri, silent, reloadCounter, setLoadingCount, setNotFound, setPermissionDenied, user, setError, setConnected])

  return { data, count, error, isLoading: loadingCount > 0, notFound }
}
