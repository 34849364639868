import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from './../hooks/usePost'
import { usePut } from './../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadButton } from '../common/RadButton'
import { RadForm } from '../common/RadForm'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'

export function PersonEdit ({ type }) {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(personId ? `/api/person/${personId}?type=${type}` : null)
  const [formValues, setFormValues] = useState()

  const create = usePost('/api/person',
    formValues,
    (resp) => navigate(`/${type}/${resp.id}`)
  )
  const update = usePut(
    `/api/person/${personId}`,
    formValues,
    (resp) => navigate(`/${type}/${personId}`)
  )

  useEffect(() => {
    const defaultFormValues = { isChampion: person?.isChampion ?? type === 'person' }
    setFormValues(person ?? defaultFormValues)
  }, [person, type])

  async function cancel () {
    navigate(personId != null ? `/${type}/${personId}` : `/${type}`)
  }

  async function saveChanges () {
    if (person && person.id) { update() } else { create() }
  }

  if (formValues != null && (personId == null || formValues.fullName != null)) {
    const title = `${personId ? 'Edit' : 'New'} ${type.charAt(0).toUpperCase() + type.slice(1)}`

    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{title}</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    Details
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  {formValues.isChampion &&
                    <RadFormField label='First Name' field='firstName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter first name'
                        value={formValues.firstName ?? ''}
                        onChange={({ detail }) => setFormValues(
                          { ...formValues, firstName: detail.value }
                        )}
                      />
                    </RadFormField>}
                  {formValues.isChampion &&
                    <RadFormField label='Last Name' field='lastName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter last name'
                        value={formValues.lastName ?? ''}
                        onChange={({ detail }) => setFormValues(
                          { ...formValues, lastName: detail.value }
                        )}
                      />
                    </RadFormField>}
                  <RadFormField label='Preferred Name' field='preferredName'>
                    <RadInput
                      ariaRequired
                      placeholder='Enter preferred name'
                      value={formValues.preferredName ?? ''}
                      onChange={({ detail }) => setFormValues({ ...formValues, preferredName: detail.value })}
                    />
                  </RadFormField>
                  {formValues.isChampion &&
                    <RadFormField label='Email' field='email'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter email'
                        value={formValues.email ?? ''}
                        onChange={({ detail }) => setFormValues(
                          { ...formValues, email: detail.value }
                        )}
                      />
                    </RadFormField>}
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
