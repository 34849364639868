import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'

export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: 'DISCO', logo: { alt: 'logo', src: '/logo.svg' } }}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null) // Chipi - I might not need this
        setActiveHref(event.detail.href)
      }}
      items={[
        { type: 'link', text: 'Dashboard', href: '/' },
        { type: 'link', text: 'Focus List Enrollments', href: '/enrollment' },
        { type: 'link', text: 'Groups', href: '/group' },
        { type: 'link', text: 'Sessions', href: '/session' },
        { type: 'link', text: 'Students', href: '/student' },
        { type: 'divider' },
        {
          type: 'section',
          text: 'Site Administration',
          items: [
            { type: 'link', text: 'Indicators', href: '/indicator' },
            { type: 'link', text: 'Organizations', href: '/organization' },
            { type: 'link', text: 'Programs', href: '/program' },
            { type: 'link', text: 'People', href: '/person' },
            { type: 'link', text: 'Roles', href: '/role' },
            { type: 'link', text: 'Sites', href: '/site' },
            { type: 'link', text: 'Types', href: '/type' },
            { type: 'link', text: 'Users', href: '/user' },
            { type: 'link', text: 'Global Notifications Settings', href: '/notifications/settings/global' }
          ]
        }
      ]}
    />
  )
}
