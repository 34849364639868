import React, { useContext } from 'react'
import Input from '@cloudscape-design/components/input'
import { AppContext } from '../App'

export function RadInput ({ ...rest }) {
  const { connected } = useContext(AppContext)
  return (
    <Input
      disabled={!connected || rest.disabled}
      {...rest}
    />
  )
}
