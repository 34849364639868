import { useEffect, useState } from 'react'
import { useGet } from '../hooks/useGet'

export function RadConnection ({ connected }) {
  const [pollCount, setPollCount] = useState(0)

  useGet(`/api/health?poll=${pollCount}`)

  useEffect(() => {
    const interval = setInterval(() => { setPollCount(p => p + 1) }, 5000)

    if (connected) {
      clearInterval(interval)
    }

    return () => { clearInterval(interval) }
  }, [connected])

  useEffect(() => {
    const handler = () => { setPollCount(1) }

    window.addEventListener('offline', handler)

    return () => { window.removeEventListener('offline', handler) }
  }, [])

  return connected
    ? null
    // Cover entire screen with transparent white overlay
    : (
      <>
        <style>
          {`
            .connection {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              pointer-events: none;
              z-index: 99999999;
            }
            .connection__overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              pointer-events: none;
              background-color: rgba(255, 255, 255, 0.75);
            }
            .connection__message {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              pointer-events: none;
              text-align: center;
              color: #000;
            }
            .connection__message h1 {
              font-size: 2rem;
              margin: 0 0 1rem;
            }
            .connection__message p {
              font-size: 1rem;
              margin: 0;
            }
          `}
        </style>
        <div className='connection'>
          <div className='connection__overlay' />
          <div className='connection__message'>
            <h1>Reconnecting...</h1>
            <p>Your device may have lost internet connection. This error will clear when your connection is restored.</p>
          </div>
        </div>
      </>
      )
}
