import React, { useContext } from 'react'
import Input from '@cloudscape-design/components/input'
import { AppContext } from '../App'

export function RadDatePicker ({ placeholder, ...rest }) {
  const { connected } = useContext(AppContext)

  return (
    <div style={{ maxWidth: '150px' }}>
      <Input
        type='date'
        {...rest}
        placeholder={placeholder ?? 'YYYY/MM/DD'}
        nextMonthAriaLabel='Next month'
        previousMonthAriaLabel='Previous month'
        todayAriaLabel='Today'
        disabled={!connected || rest.disabled}
      />
    </div>
  )
}
