import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toTitleCase } from '../common/utilities'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadForm } from '../common/RadForm'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadFormField } from '../common/RadFormField'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadInput } from '../common/RadInput'

export function TypeEdit () {
  const navigate = useNavigate()
  const { entity } = useParams()
  const { data: types } = useGet(entity ? `/api/type?entity=${entity}` : null)
  const [formValues, setFormValues] = useState(types)
  const create = usePost('/api/type', formValues, (resp) => { navigate('/type') })

  useEffect(() => {
    setFormValues(types ? { entity, types } : { entity, types: [] })
  }, [types, entity])

  async function cancel () {
    navigate('/type')
  }

  async function saveChanges () {
    create()
  }

  if (types) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Types', href: '/type' },
              { text: `${toTitleCase(entity)}`, href: '/type' }, // Chipi
              { text: 'Edit' }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader variant='h1'>Edit Type</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    {toTitleCase(entity)} Types
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const temp = formValues.types
                      temp.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, types: temp })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const temp = [...formValues.types]
                      temp.splice(itemIndex, 1)
                      setFormValues({ ...formValues, types: temp })
                    }}
                    items={formValues.types}
                    addButtonText='Add type'
                    definition={[
                      {
                        label: 'Type',
                        control: item =>
                          <RadFormField field={`type.${item.id || item.uuid}.name`}>
                            <RadInput
                              ariaRequired
                              value={item.name ?? ''}
                              onChange={({ detail }) => {
                                const temp = formValues.types
                                const type = formValues.types.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                type.name = detail.value
                                setFormValues({ ...formValues, types: temp })
                              }}
                            />
                          </RadFormField>
                      }
                    ]}
                    removeButtonText='Remove'
                  />
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
