import { useContext } from 'react'
import { AppContext } from './../App'

export function useConfirm (title, message, onSuccess) {
  const { setConfirmModal } = useContext(AppContext)

  async function confirm () {
    setConfirmModal({ visible: true, title: title, message: message, onSuccess: onSuccess })
  }

  return confirm
}
