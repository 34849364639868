import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RadButton } from '../../common/RadButton'
import { RadButtonDropdown } from '../../common/RadButtonDropdown'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { LogSessionFromMessageForm } from './LogSessionFromMessageForm'

export function LogSessionFromMessage ({
  personId,
  conversationSid,
  messages,
  setSelecting,
  selectedMessages,
  setSelectedMessages
}) {
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)
  const mostRecentMessage = useMemo(
    () => messages != null ? messages[messages.length - 1] : null,
    [messages]
  )

  if (showForm) {
    return (
      <LogSessionFromMessageForm
        personId={personId}
        conversationSid={conversationSid}
        selectedMessages={selectedMessages}
        onSuccess={(resp) => {
          setShowForm(false)
          setSelectedMessages([])
          navigate(`/session/${resp.id}`)
        }}
        onDismiss={() => {
          setShowForm(false)
          setSelectedMessages([])
        }}
      />
    )
  }

  return (
    <LogSessionControl
      disableSubmit={selectedMessages.length < 1}
      onMostRecent={() => {
        setShowForm(true)
        setSelectedMessages([mostRecentMessage])
      }}
      onSelecting={() => {
        setSelecting(true)
        setSelectedMessages([])
      }}
      onSelected={() => {
        setSelecting(false)
        setShowForm(selectedMessages.length > 0)
      }}
      onCancel={() => {
        setSelecting(false)
        setSelectedMessages([])
      }}
    />
  )
}

function LogSessionControl ({ onMostRecent, onSelecting, onSelected, onCancel, disableSubmit }) {
  const [selecting, setSelecting] = useState(false)

  if (selecting) {
    return (
      <RadSpaceBetween direction='horizontal' size='xs'>
        <RadButton
          iconName='check'
          onClick={() => {
            setSelecting(false)
            onSelected()
          }}
          disabled={disableSubmit}
          variant='primary'
        >
          Log Session
        </RadButton>
        <RadButton
          iconName='close'
          onClick={() => {
            setSelecting(false)
            onCancel()
          }}
        />
      </RadSpaceBetween>
    )
  }

  return (
    <RadButtonDropdown
      variant='primary'
      items={[{
        id: 'log_session',
        items: [{
          id: 'recent',
          text: 'From Latest',
          iconName: 'envelope'
        }, {
          id: 'select',
          text: 'From Selected',
          iconName: 'check'
        }]
      }]}
      onItemClick={({ detail }) => {
        const { id } = detail
        switch (id) {
          case 'recent':
            onMostRecent()
            break
          case 'select':
            setSelecting(true)
            onSelecting()
            break
          default:
            console.log(detail.id)
        }
      }}
    >
      Log Session
    </RadButtonDropdown>
  )
}
