import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadSelect } from '../common/RadSelect'
import { RadButton } from '../common/RadButton'
import { RadTextarea } from '../common/RadTextarea'

export function ProgramEdit () {
  const navigate = useNavigate()
  const { programId } = useParams()
  const { data: program } = useGet(programId ? `/api/program/${programId}` : null)
  const [formValues, setFormValues] = useState()

  const create = usePost('/api/program', formValues, (resp) => { navigate(`/program/${resp.id}`) })
  const update = usePut(`/api/program/${programId}`, formValues, (resp) => { navigate(`/program/${programId}`) })

  useEffect(() => {
    const defaultFormValues = { exitReasons: [], goals: [], services: [] }
    setFormValues(program ?? defaultFormValues)
  }, [program])

  async function cancel () {
    if (programId) {
      navigate(`/program/${programId}`)
    } else {
      navigate('/program')
    }
  }

  async function saveChanges () {
    if (program?.id) { update() } else { create() }
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit Program' : 'New Program'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details formValues={formValues} setFormValues={setFormValues} />
                <Indicators formValues={formValues} setFormValues={setFormValues} />
                <Goals formValues={formValues} setFormValues={setFormValues} />
                <ExitReasons formValues={formValues} setFormValues={setFormValues} />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details ({ formValues, setFormValues }) {
  const { data: typeOptions } = useGet('/api/option/type?entity=program')

  if (typeOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h2'>
            Details
          </RadHeader>
    }
      >
        <RadSpaceBetween size='l'>
          <RadFormField label='Name' field='name'>
            <RadInput
              ariaRequired
              placeholder='Enter name'
              value={formValues.name ?? ''}
              onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
            />
          </RadFormField>
          <RadFormField label='Type' field='typeId'>
            <RadSelect
              selectedOption={typeOptions.find(x => x.value === (formValues.typeId?.toString() ?? ''))}
              onChange={({ detail }) => {
                if (detail.selectedOption.value !== '') {
                  setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                } else {
                  setFormValues({ ...formValues, typeId: null })
                }
              }}
              options={typeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Description' field='description'>
            <RadTextarea
              placeholder='Enter description'
              value={formValues.description ?? ''}
              onChange={event => setFormValues({ ...formValues, description: event.detail.value })}
            />
          </RadFormField>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Indicators ({ formValues, setFormValues }) {
  const { data: serviceOptions } = useGet('/api/option/service')

  if (serviceOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            counter={'(' + formValues.services.length + ')'}
            variant='h2'
          >
            Indicators
          </RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const services = formValues.services
            services.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, services })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const services = [...formValues.services]
            services.splice(itemIndex, 1)
            setFormValues({ ...formValues, services })
          }}
          items={formValues.services}
          addButtonText='Add indicator'
          definition={[
            {
              control: item => {
                const filteredOptions = serviceOptions.filter((x) => !formValues.services.map((y) => y.id?.toString()).includes(x.value))
                return (
                  <RadFormField field={`services.${item.id || item.uuid}.id`}>
                    <RadSelect
                      selectedOption={serviceOptions.find(x => x.value === item.id?.toString())}
                      onChange={({ detail }) => {
                        const services = formValues.services
                        item.id = parseInt(detail.selectedOption.value)
                        setFormValues({ ...formValues, services })
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose an indicator'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No indicators associated with this program.'
        />
      </RadContainer>
    )
  }
}

function Goals ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + formValues.goals.length + ')'}
          variant='h2'
        >
          Goals
        </RadHeader>
        }
    >
      <RadAttributeEditor
        onAddButtonClick={() => {
          const goals = formValues.goals
          goals.push({ uuid: uuidv4() })
          setFormValues({ ...formValues, goals })
        }}
        onRemoveButtonClick={({
          detail: { itemIndex }
        }) => {
          const goals = [...formValues.goals]
          goals.splice(itemIndex, 1)
          setFormValues({ ...formValues, goals })
        }}
        items={formValues.goals}
        addButtonText='Add goal'
        definition={[
          {
            control: item => {
              return (
                <RadFormField field={`goals.${item.id || item.uuid}.name`}>
                  <RadInput
                    onChange={({ detail }) => {
                      const goals = formValues.goals
                      item.name = detail.value
                      setFormValues({ ...formValues, goals })
                    }}
                    placeholder='Enter name'
                    value={item.name}
                  />
                </RadFormField>
              )
            }
          }
        ]}
        removeButtonText='Remove'
        empty='No goals associated with this program.'
      />
    </RadContainer>
  )
}

function ExitReasons ({ formValues, setFormValues }) {
  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + formValues.exitReasons.length + ')'}
          variant='h2'
        >
          Exit Reasons
        </RadHeader>
        }
    >
      <RadAttributeEditor
        onAddButtonClick={() => {
          const exitReasons = formValues.exitReasons
          exitReasons.push({ uuid: uuidv4() })
          setFormValues({ ...formValues, exitReasons })
        }}
        onRemoveButtonClick={({
          detail: { itemIndex }
        }) => {
          const exitReasons = [...formValues.exitReasons]
          exitReasons.splice(itemIndex, 1)
          setFormValues({ ...formValues, exitReasons })
        }}
        items={formValues.exitReasons}
        addButtonText='Add exit reason'
        definition={[
          {
            control: item => {
              return (
                <RadFormField field={`exitReasons.${item.id || item.uuid}.name`}>
                  <RadInput
                    onChange={({ detail }) => {
                      const exitReasons = formValues.exitReasons
                      item.name = detail.value
                      setFormValues({ ...formValues, exitReasons })
                    }}
                    value={item.name}
                  />
                </RadFormField>
              )
            }
          }
        ]}
        removeButtonText='Remove'
        empty='No exit reasons associated with this program.'
      />
    </RadContainer>
  )
}
