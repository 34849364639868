import React, { useContext } from 'react'
import Select from '@cloudscape-design/components/select'
import { AppContext } from '../App'

export function RadSelect ({ filteringAriaLabel, ...rest }) {
  const { connected } = useContext(AppContext)

  return (
    <Select
      {...rest}
      filteringAriaLabel={filteringAriaLabel ?? rest.filteringType != null ? 'search' : null}
      disabled={!connected || rest.disabled}
    />
  )
}
