import React from 'react'
import './SignIn.scss'

export function SignIn () {
  return (
    <div className='sign-in'>
      <div className='content'>
        <h2>DISCO</h2>
        <span>Case Management by Asemio</span>
      </div>
    </div>
  )
}
