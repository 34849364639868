import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
// import { RadTable } from '../common/RadTable'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadPagination } from '../common/RadPagination'
import { RadButton } from '../common/RadButton'

export function SiteList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: sites, count } = useGet(`/api/site?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  if (sites) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/site/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Sites
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/site/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'description',
                  content: item => item.description
                },
                {
                  id: 'type',
                  header: 'Type',
                  content: item => item.type?.name ?? '-'
                },
                {
                  id: 'parent',
                  header: 'Parent',
                  content: item => item.parent?.name ?? '-'
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={sites}
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search'
                filteringAriaLabel='Search sites'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.set('page', 1)
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
          // <RadTable
          //   ariaLabels={{
          //     selectionGroupLabel: 'Items selection',
          //     allItemsSelectionLabel: ({ selectedItems }) => `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
          //     itemSelectionLabel: ({ selectedItems }, item) => {
          //       const isItemSelected = selectedItems.filter(i => i.name === item.name).length
          //       return `${item.name} is ${isItemSelected ? '' : 'not'
          //         } selected`
          //     }
          //   }}
          //   columnDefinitions={[
          //     {
          //       id: 'name',
          //       header: 'Name',
          //       cell: e => <RadLink href={`/site/${e.id}`}>{e.name}</RadLink>
          //     },
          //     {
          //       id: 'type',
          //       header: 'Type',
          //       cell: e => e.type?.name ?? '-'
          //     },
          //     {
          //       id: 'parentId',
          //       header: 'Parent',
          //       cell: e => e.parent?.name ?? '-'
          //     }
          //   ]}
          //   items={sites ?? []}
          //   loadingText='Loading resources'
          //   trackBy='name'
          //   variant='full-page'
          //   filter={
          //     <RadTextFilter
          //       filteringPlaceholder='Search'
          //       filteringText={filteringText}
          //       onChange={({ detail }) => setFilteringText(detail.filteringText)}
          //       onDelayedChange={({ detail }) => {
          //         setSearchText(detail.filteringText)
          //         setCurrentPageIndex(1)
          //         searchParams.set('page', 1)
          //         if (detail.filteringText) {
          //           searchParams.set('search', detail.filteringText)
          //         } else {
          //           searchParams.delete('search')
          //         }
          //         setSearchParams(searchParams)
          //       }}
          //     />
          //   }
          //   header
          //   pagination={
          //     <RadPagination
          //       currentPageIndex={1}
          //       pagesCount={(sites?.length ?? 0) / 10}
          //       ariaLabels={{
          //         nextPageLabel: 'Next page',
          //         previousPageLabel: 'Previous page',
          //         pageLabel: pageNumber =>
          //           `Page ${pageNumber} of all pages`
          //       }}
          //     />
          //   }
          // />
        }
      />
    )
  }
}
