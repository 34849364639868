import React, { useMemo, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadCards } from '../common/RadCards'
import { RadPagination } from '../common/RadPagination'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadBox } from '../common/RadBox'
import { Avatar } from '@rmwc/avatar'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadButton } from '../common/RadButton'
import { getGrade, numberWithCommas } from '../common/utilities'
import { RadGrid } from '../common/RadGrid'
import { RadSelect } from '../common/RadSelect'

export function PersonList ({ type }) {
  const navigate = useNavigate()
  const pageLength = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [schoolFilterText, setSchoolFilterText] = useState(searchParams.get('school') ?? '')
  const { data: gradeOptions } = useGet('/api/option/grade')
  const { data: schoolOptions } = useGet(`/api/option/school?search=${schoolFilterText}`)

  const selectedGradeOption = useMemo(
    () => searchParams.has('grade')
      ? { value: searchParams.get('grade'), label: getGrade(parseInt(searchParams.get('grade'))) }
      : { value: '', label: 'Any grade' },
    [searchParams]
  )

  const selectedSchoolOption = useMemo(
    () => searchParams.has('school') &&
    schoolOptions != null
      ? schoolOptions.find(x => x.label === searchParams.get('school'))
      : { value: '', label: 'Any school' },
    [searchParams, schoolOptions]
  )

  const queryParams = useMemo(
    () => [
      `search=${searchText}`,
      `type=${type}`,
      searchParams.has('grade')
        ? `grade=${searchParams.get('grade')}`
        : null,
      selectedSchoolOption.value !== ''
        ? `schoolId=${selectedSchoolOption.value}`
        : null,
      `limit=${pageLength}`,
      `offset=${(currentPageIndex - 1) * pageLength}`
    ].filter(x => x != null).join('&'),
    [searchText, type, searchParams, selectedSchoolOption, pageLength, currentPageIndex]
  )

  const { data: people, count } = useGet(`/api/person?${queryParams}`)

  const typeSpecific = {
    student: { title: 'Students', namespace: 'student', headerActions: [<div key={0} />] },
    person: {
      title: 'People',
      namespace: 'person',
      headerActions: [
        <RadButton key={0} onClick={() => navigate('/person/create')}>Create</RadButton>
      ]
    }
  }

  const { title, namespace, headerActions } = typeSpecific[type]

  if (people) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {headerActions}
              </RadSpaceBetween>
            }
            counter={'(' + numberWithCommas(count) + ')'}
          >
            {title}
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={`${item.firstName} ${item.lastName}`}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/${namespace}/${item.id}`}>{`${item.firstName} ${item.preferredName ? '"' + item.preferredName + '" ' : ''}${item.lastName}`}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'schoolEnrollment',
                  content: item => item.schoolEnrollments?.length > 0
                    ? <><div>{item.schoolEnrollments[0].school.name}</div><div>{getGrade(item.schoolEnrollments[0].grade)}</div></>
                    : <div />
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={people}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 3 } },
                  { colspan: { default: 12, xs: 3 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel={`Search ${title}`}
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={selectedGradeOption}
                  onChange={({ detail }) => {
                    if (detail.selectedOption.value !== '') {
                      searchParams.set('grade', detail.selectedOption.value)
                    } else {
                      searchParams.delete('grade')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={gradeOptions != null
                    ? [
                        { value: '', label: 'Any grade' },
                        ...gradeOptions.map(x => ({ ...x, label: getGrade(parseInt(x.value)) }))
                      ]
                    : []}
                  selectedAriaLabel='Selected'
                  placeholder='Select grade'
                  empty={gradeOptions ? 'loading...' : null}
                />
                <RadSelect
                  selectedOption={selectedSchoolOption}
                  onChange={({ detail }) => {
                    if (detail.selectedOption.value !== '') {
                      searchParams.set('school', detail.selectedOption.label)
                    } else {
                      searchParams.delete('school')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={schoolOptions != null
                    ? [
                        { value: '', label: 'Any school' },
                        ...schoolOptions
                      ]
                    : []}
                  filteringType='manual'
                  onLoadItems={({ detail }) => setSchoolFilterText(detail.filteringText)}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Select school'
                  empty={schoolFilterText ? 'No schools found' : null}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
