import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadAvatar } from '../common/RadAvatar'
import { RadLink } from '../common/RadLink'
import { RadBox } from '../common/RadBox'
import { RadPagination } from '../common/RadPagination'
import { getGrade, numberWithCommas } from '../common/utilities'

export function GroupDetail () {
  const cardsPerPage = 20
  const navigate = useNavigate()
  const { groupId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const { data: group, count } = useGet(`/api/group/${groupId}?limit=${cardsPerPage}&offset=${(currentPageIndex - 1) * cardsPerPage}`)
  const confirmDelete = useConfirm('Delete group?', 'Delete group permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/group/${groupId}`, () => { navigate('/group') })

  if (group != null) {
    return (
      <RadAppLayout
        name={group.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')} disabled={group.isProtected}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={group.isProtected}>Delete</RadButton>
                <RadButton disabled={count > 1200} onClick={() => navigate(`/session/create?groupIds=${group.id}`)}>Create Session</RadButton>
              </RadSpaceBetween>
            }
            description={group.description}
            counter={'(' + numberWithCommas(count) + ')'}
          >
            {group.name}
          </RadHeader>
        }
        disableContentHeaderOverlap={group.people.length === 0}
        content={
          <RadCards
            cardDefinition={{
              header: item => (
                <>
                  <RadAvatar
                    src={item.photoUrl}
                    size='large'
                    name={`${item.firstName} ${item.lastName}`}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/student/${item.id}`}>{`${item.firstName} ${item.preferredName ? '"' + item.preferredName + '" ' : ''} ${item.lastName}`}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'schoolEnrollment',
                  content: item => item.schoolEnrollments.length > 0
                    ? <><div>{item.schoolEnrollments[0].school.name}</div><div>{getGrade(item.schoolEnrollments[0].grade)}</div></>
                    : <div />
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={group.people ?? []}
            pagination={
              <RadPagination
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
                currentPageIndex={currentPageIndex}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                pagesCount={Math.ceil(count / cardsPerPage)}
              />
            }
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit' padding={{ top: 'l' }}>
                <RadBox
                  variant='p'
                  color='inherit'
                >
                  No students associated with this group.
                </RadBox>
              </RadBox>
            }
          />
        }
      />
    )
  }
}
