import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function NotificationsSettingsEdit ({ type }) {
  const navigate = useNavigate()
  const { data: userPreferences } = useGet('/api/user-preferences/current')
  const [formValues, setFormValues] = useState()

  useEffect(() => {
    const defaultFormValues = { consecutiveAbsencesNotificationMinimum: 3, consecutiveAbsencesNotificationEnabled: false }
    setFormValues(userPreferences ?? defaultFormValues)
  }, [userPreferences])

  const update = usePut(
    '/api/user-preferences/current',
    formValues,
    (resp) => navigate('/notifications/settings')
  )

  async function cancel () {
    navigate('/notifications/settings')
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>Preferences</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={update}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    Consecutive Absences Notification
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  <RadFormField label='Number of consecutive absences' field='consecutiveAbsencesNotificationMinimum'>
                    <RadInput
                      ariaRequired
                      placeholder='Enter number of consecutive absences'
                      value={formValues.consecutiveAbsencesNotificationMinimum}
                      onChange={({ detail }) => setFormValues({ ...formValues, consecutiveAbsencesNotificationMinimum: detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Enabled' field='consecutiveAbsencesNotificationEnabled'>
                    <RadCheckbox
                      checked={formValues.consecutiveAbsencesNotificationEnabled}
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, consecutiveAbsencesNotificationEnabled: detail.checked })
                      }}
                    />
                  </RadFormField>
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
