import './PersonMessage.scss'
import Alert from '@cloudscape-design/components/alert'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import { Client as ConversationsClient } from '@twilio/conversations'
import { AppContext } from '../../App'
import { RadAppLayout } from '../../common/RadAppLayout'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadHeader } from '../../common/RadHeader'
import { RadButton } from '../../common/RadButton'
import { RadPermissionDenied } from '../../common/RadPermissionDenied'
import { ConversationsList } from './ConversationsList'

export function PersonMessage ({ type }) {
  const navigate = useNavigate()
  const { setWarning } = useContext(AppContext)
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}?type=${type}`)
  const { data: token } = useGet('/api/conversation/token')
  const [conversations, setConversations] = useState([])
  const [connected, setConnected] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [alert, setAlert] = useState(null)
  const conversationsClient = useMemo(() => {
    if (token == null) return null
    return new ConversationsClient(token.jwt)
  }, [token])
  const joinExistingConversations = usePost(
    `/api/conversation/${personId}/join`,
    {},
    () => { setFetched(true) }
  )

  const conversationJoined = useCallback((newConversation) => {
    const thisPersonId = newConversation.uniqueName.split('-')[0]
    if (thisPersonId !== personId) return

    console.log('conversationJoined', newConversation.sid)
    setConversations(c => [
      ...c.filter(it => it.sid !== newConversation.sid),
      newConversation
    ])
  }, [personId])

  const conversationLeft = useCallback((oldConversation) => {
    console.log('conversationLeft', oldConversation.sid)
    setConversations(c => [...c.filter(it => it.sid !== oldConversation.sid)])
  }, [])

  const connectionStateChanged = useCallback((state) => {
    const statuses = {
      connecting: { type: 'info', title: 'Connecting...', message: 'Connecting to Twilio…' },
      connected: { type: 'success', title: 'Connected', message: 'You are connected to Twilio.' },
      disconnecting: { type: 'info', title: 'Disconnecting...', message: 'Disconnecting from Twilio…' },
      disconnected: { type: 'error', title: 'Disconnected', message: 'You are disconnected from Twilio.' },
      denied: { type: 'error', title: 'Connection Failed', message: 'Failed to connect to Twilio.' }
    }

    setAlert(statuses[state])

    if (state === 'connected' && conversationsClient != null && !fetched) {
      joinExistingConversations()
      conversationsClient.on('conversationJoined', conversationJoined)
      conversationsClient.on('conversationLeft', conversationLeft)
    }
  }, [conversationsClient, fetched, joinExistingConversations, conversationJoined, conversationLeft])

  useEffect(() => {
    if (conversationsClient != null && !connected) {
      console.log('connected')
      conversationsClient.on('connectionStateChanged', connectionStateChanged)
      setConnected(true)
    }
  }, [conversationsClient, connected, connectionStateChanged])

  if (person != null && token == null) {
    return (<RadPermissionDenied />)
  }

  if (person != null) {
    return (
      <RadAppLayout
        name={person.fullName}
        contentHeader={
          <RadSpaceBetween size='l'>
            <RadHeader
              variant='h1'
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton iconName='angle-left' onClick={() => navigate(`/${type}/${personId}`)}>Back</RadButton>
                </RadSpaceBetween>
              }
            >
              {`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
            </RadHeader>
            {
              alert != null &&
                <Alert type={alert.type} header={alert.title}>
                  {alert.message}
                </Alert>
            }
          </RadSpaceBetween>
        }
        content={
          <ConversationsList
            personId={person.id}
            conversations={[null, ...conversations]}
            setWarning={setWarning}
            connected={connected}
          />
        }
      />
    )
  }
}
