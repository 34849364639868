import React, { useContext, useState, useEffect } from 'react'
import Textarea from '@cloudscape-design/components/textarea'
import { AppContext } from '../App'

export function RadTextarea ({ onChange, onDelayedChange, delay = 1500, ...rest }) {
  const { connected } = useContext(AppContext)
  const [value, setValue] = useState(rest.value || '')

  useEffect(() => {
    const handler = setTimeout(() => {
      if (onDelayedChange) {
        onDelayedChange({ detail: { value } })
      }
    }, delay)

    return () => { clearTimeout(handler) }
  }, [value])

  const handleChange = (event) => {
    setValue(event.detail.value)
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <Textarea
      disabled={!connected || rest.disabled}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  )
}
