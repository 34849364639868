import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'

export function ServiceList ({ selected }) {
  const navigate = useNavigate()
  const { data: services } = useGet('/api/service')

  if (services) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/indicator/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + services.length + ')'}
          >
            Indicators
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/indicator/${item.id}`}>{item.name}</RadLink>
              ),
              sections: [
                {
                  id: 'description',
                  content: item => item.description
                },
                {
                  id: 'interventions',
                  header: 'Interventions',
                  content: item => item.deliveries.map((x) => { return <div key={x.id}>{x.name}</div> })
                },
                {
                  id: 'skills',
                  header: 'Skills',
                  content: item => {
                    return item.skills.length > 0
                      ? item.skills.map((x) => { return <div key={x.id}>{x.name}</div> })
                      : <div>-</div>
                  }
                },
                {
                  id: 'programs',
                  header: 'Programs',
                  content: item => {
                    return item.programs.length > 0
                      ? item.programs.map((x) => <div key={x.id}><RadLink href={`/program/${x.id}`}>{x.name}</RadLink></div>)
                      : <div>-</div>
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={services ?? []}
            loadingText='Loading resources'
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit'>
                <b>No resources</b>
                <RadBox
                  padding={{ bottom: 's' }}
                  variant='p'
                  color='inherit'
                >
                  No resources to display.
                </RadBox>
                {/* <RadButton>Create resource</RadButton> */}
              </RadBox>
            }
          />
        }
      />
    )
  }
}
