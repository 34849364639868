import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadButton } from '../common/RadButton'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadLink } from '../common/RadLink'
import { RadBox } from '../common/RadBox'

export function ProgramDetail () {
  const navigate = useNavigate()
  const { programId } = useParams()
  const { data: program } = useGet(`/api/program/${programId}`)
  const confirmDelete = useConfirm('Delete program?', 'Delete program permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/program/${programId}`, () => { navigate('/program') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (program) {
    return (
      <RadAppLayout
        name={program.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={program.description}
          >
            {program.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={2}>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Type
                  </RadBox>
                  <div>{program.type?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Indicators
                  </RadBox>
                  {program.services.map((service) => {
                    return (
                      <RadLink key={service.id} href={`/indicator/${service.id}`}>{service.name}</RadLink>
                    )
                  })}
                  {program.services.length === 0 && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Goals
                  </RadBox>
                  {program.goals.map((goal) => {
                    return (
                      <div key={goal.id}>{goal.name}</div>
                    )
                  })}
                  {program.goals.length === 0 && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Exit Reasons
                  </RadBox>
                  {program.exitReasons.map((exitReason) => {
                    return (
                      <div key={exitReason.id}>{exitReason.name}</div>
                    )
                  })}
                  {program.exitReasons.length === 0 && <div>-</div>}
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
