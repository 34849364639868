import React, { useContext } from 'react'
import { AppContext } from './../App'
import ButtonDropdown from '@cloudscape-design/components/button-dropdown'

export function RadButtonDropdown ({ disabled, ...rest }) {
  const { loadingCount } = useContext(AppContext)

  return (
    <ButtonDropdown
      disabled={loadingCount > 0 ? true : disabled}
      {...rest}
    />
  )
}
