import React, { useState } from 'react'
import { auth } from '../firebase'
import { RadBox } from '../common/RadBox'
import { RadBadge } from '../common/RadBadge'
import TopNavigation from '@cloudscape-design/components/top-navigation'
import { RadNotificationInbox } from './RadNotificationInbox'

export function RadTopNavigation ({ stopNotificationsHref }) {
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const [showInbox, setShowInbox] = useState(false)
  const root = window.location.hostname.split('.')[0]
  let search

  switch (root) {
    case 'qa':
    case 'uat':
      search = <RadBox textAlign='center'><RadBadge color='green'>{root.toUpperCase()}</RadBadge></RadBox>
      break
    default:
  }

  return (
    <>
      <TopNavigation
        identity={{
          href: '/',
          title: 'Case Management'
        }}
        utilities={[
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: hasUnreadNotifications ? 'Notifications (unread)' : 'Notifications',
            badge: hasUnreadNotifications,
            disableUtilityCollapse: false,
            onClick: () => {
              setShowInbox(true)
              setHasUnreadNotifications(false)
            }
          },
          {
            type: 'menu-dropdown',
            text: auth.currentUser?.displayName,
            description: auth.currentUser?.email,
            iconName: 'user-profile',
            onItemClick: event => {
              switch (event.detail.id) {
                case 'signout':
                  auth.signOut()
                  break
                default:
                  console.log(`${event.detail.id} not implemented`)
              }
            },
            items: [
              { id: 'signout', text: 'Sign out' }
            ]
          }
        ]}
        i18nStrings={{
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All',
          overflowMenuBackIconAriaLabel: 'Back',
          overflowMenuDismissIconAriaLabel: 'Close menu'
        }}
        search={search}
      />
      <RadNotificationInbox
        onUpdated={(hasUnread) => setHasUnreadNotifications(hasUnread)}
        show={showInbox}
        setShow={setShowInbox}
        stopNotificationsHref={stopNotificationsHref}
      />
    </>
  )
}
