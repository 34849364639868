import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadTable } from '../common/RadTable'
import { formatDate, getGrade, numberWithCommas } from '../common/utilities'

export function OrganizationDetail () {
  const cardsPerPage = 20
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(`/api/organization/${organizationId}`)
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: schoolEnrollments, count } = useGet(
    organization?.school
      ? `/api/school-enrollments?schoolId=${organization.school.id}&search=${searchText}&limit=${cardsPerPage}&offset=${(currentPageIndex - 1) * cardsPerPage}`
      : null
  )
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (organization) {
    return (
      <RadAppLayout
        name={organization.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={organization.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={organization.description}
          >
            {organization.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Parent Organization
                  </RadBox>
                  {organization.parent && <RadLink href={`/organization/${organization.parent.id}`}>{organization.parent.name}</RadLink>}
                  {!organization.parent && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Type
                  </RadBox>
                  <div>{organization.type?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Address
                  </RadBox>
                  <div>{organization.address?.line1}</div>
                  {organization.address?.line2 && <div>{organization.address?.line2}</div>}
                  <div>{organization.address?.city} {organization.address?.state} {organization.address?.zip}</div>
                  {!organization.addressId && '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phone
                  </RadBox>
                  <div>{organization.phone ?? '-'}</div>
                </div>
              </RadColumnLayout>
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Child Organizations
                  </RadBox>
                  {organization.organizations.map((child) => {
                    return (
                      <div key={child.id}><RadLink href={`/organization/${child.id}`}>{child.name}</RadLink></div>
                    )
                  })}
                  {organization.organizations.length === 0 && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Child Sites
                  </RadBox>
                  {organization.sites.map((child) => {
                    return (
                      <div key={child.id}><RadLink href={`/site/${child.id}`}>{child.name}</RadLink></div>
                    )
                  })}
                  {organization.sites.length === 0 && <div>-</div>}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Caseworkers
                  </RadBox>
                  {organization.users.map((child) => {
                    return (
                      <div key={child.id}><RadLink href={`/user/${child.id}`}>{child.name}</RadLink></div>
                    )
                  })}
                  {organization.users.length === 0 && <div>-</div>}
                </div>
              </RadColumnLayout>
            </RadContainer>

            {organization.school && schoolEnrollments &&
              <RadTable
                variant='container'
                header={
                  <RadHeader
                    counter={'(' + numberWithCommas(count) + ')'}
                  >
                    Students
                  </RadHeader>
                }
                columnDefinitions={[
                  {
                    id: 'number',
                    header: 'Number',
                    cell: e => e.studentNumber
                  },
                  {
                    id: 'name',
                    header: 'Name',
                    cell: e => <RadLink href={`/student/${e.person.id}`}>{`${e.person?.firstName} ${e.person.preferredName ? '"' + e.person.preferredName + '" ' : ''} ${e.person?.lastName}`}</RadLink>
                  },
                  {
                    id: 'grade',
                    header: 'Grade',
                    cell: e => getGrade(e.grade)
                  },
                  {
                    id: 'startDate',
                    header: 'Start Date',
                    cell: e => formatDate(e.startDate)
                  },
                  {
                    id: 'endDate',
                    header: 'End Date',
                    cell: e => formatDate(e.endDate)
                  }
                ]}
                items={schoolEnrollments}
                filter={
                  <RadTextFilter
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search students'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      setCurrentPageIndex(1)
                      searchParams.set('page', 1)
                      if (detail.filteringText) {
                        searchParams.set('search', detail.filteringText)
                      } else {
                        searchParams.delete('search')
                      }
                      setSearchParams(searchParams)
                    }}
                  />
                }
                pagination={
                  <RadPagination
                    ariaLabels={{
                      nextPageLabel: 'Next page',
                      previousPageLabel: 'Previous page',
                      pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                    }}
                    currentPageIndex={currentPageIndex}
                    onChange={({ detail }) => {
                      searchParams.set('page', detail.currentPageIndex)
                      setSearchParams(searchParams)
                      setCurrentPageIndex(detail.currentPageIndex)
                    }}
                    pagesCount={Math.ceil(count / cardsPerPage)}
                  />
                }
              />}

            {/* <RadCards
              header={
                <RadHeader
                  counter={'(' + count + ')'}
                >
                  Students
                </RadHeader>
              }
              cardDefinition={{
                header: item => (
                  <>
                    <RadAvatar
                      src={item.person.photoUrl}
                      size='large'
                      name={`${item.person.firstName} ${item.person.lastName}`}
                      referrerPolicy='no-referrer'
                    />
                    <br />
                    <RadLink fontSize='heading-m' href={`/student/${item.person.id}`}>{item.person.fullName}</RadLink>
                  </>
                ),
                sections: [
                  {
                    id: 'schoolEnrollment',
                    content: item => <div>{getGrade(item.grade)} grade</div>
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={schoolEnrollments}
              filter={
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search organizations'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
              }
              pagination={
                <RadPagination
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                  currentPageIndex={currentPageIndex}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  pagesCount={Math.ceil(count / cardsPerPage)}
                />
              }
              empty={filteringText ? 'No matches found' : null}
            /> */}
          </RadSpaceBetween>
        }
      />
    )
  }
}
