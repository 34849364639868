import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadDeleteConfirmationModal } from './common/RadDeleteConfirmationModal'
import { RadConnection } from './common/RadConnection'
import { CaseList } from './case/CaseList'
import { CaseDetail } from './case/CaseDetail'
import { CaseEdit } from './case/CaseEdit'
import { Dashboard } from './dashboard/Dashboard'
import { GroupList } from './group/GroupList'
import { GroupDetail } from './group/GroupDetail'
import { GroupEdit } from './group/GroupEdit'
import { PersonList } from './person/PersonList'
import { PersonDetail } from './person/PersonDetail'
import { PersonEdit } from './person/PersonEdit'
import { PersonMessage } from './person/PersonMessage/PersonMessage'
import { NotificationsSettings } from './notifications/NotificationsSettings'
import { NotificationsSettingsEdit } from './notifications/NotificationsSettingsEdit'
import { GlobalNotificationsSettings } from './notifications/GlobalNotificationsSettings'
import { ProgramList } from './program/ProgramList'
import { ProgramDetail } from './program/ProgramDetail'
import { ProgramEdit } from './program/ProgramEdit'
import { OrganizationList } from './organization/OrganizationList'
import { OrganizationDetail } from './organization/OrganizationDetail'
import { OrganizationEdit } from './organization/OrganizationEdit'
import { RoleList } from './role/RoleList'
import { RoleEdit } from './role/RoleEdit'
import { RoleDetail } from './role/RoleDetail'
import { ServiceList } from './service/ServiceList'
import { ServiceDetail } from './service/ServiceDetail'
import { ServiceEdit } from './service/ServiceEdit'
import { SessionList } from './session/SessionList'
import { SessionDetail } from './session/SessionDetail'
import { SessionEdit } from './session/SessionEdit'
import { SiteList } from './site/SiteList'
import { SiteDetail } from './site/SiteDetail'
import { SiteEdit } from './site/SiteEdit'
import { TypeList } from './type/TypeList'
import { TypeEdit } from './type/TypeEdit'
import { UserList } from './user/UserList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [connected, setConnected] = useState(true)
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [warning, setWarning] = useState()
  const [info, setInfo] = useState()
  const [success, setSuccess] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })
  const [stopNotificationsHref, setStopNotificationsHref] = useState(null)

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        warning,
        setWarning,
        info,
        setInfo,
        success,
        setSuccess,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal,
        setConnected,
        connected,
        setStopNotificationsHref
      }}
      >
        <RadTopNavigation stopNotificationsHref={stopNotificationsHref} />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/enrollment' element={<CaseList />} />
          <Route path='/enrollment/:enrollmentId' element={<CaseDetail />} />
          <Route path='/enrollment/create' element={<CaseEdit />} />
          <Route path='/enrollment/:enrollmentId/edit' element={<CaseEdit />} />

          <Route path='/group' element={<GroupList />} />
          <Route path='/group/create' element={<GroupEdit />} />
          <Route path='/group/:groupId' element={<GroupDetail />} />
          <Route path='/group/:groupId/edit' element={<GroupEdit />} />

          <Route path='/organization' element={<OrganizationList />} />
          <Route path='/organization/create' element={<OrganizationEdit />} />
          <Route path='/organization/:organizationId' element={<OrganizationDetail />} />
          <Route path='/organization/:organizationId/edit' element={<OrganizationEdit />} />

          <Route path='/person' element={<PersonList type='person' />} />
          <Route path='/person/:personId' element={<PersonDetail type='person' />} />
          <Route path='/person/create' element={<PersonEdit type='person' />} />
          <Route path='/person/:personId/edit' element={<PersonEdit type='person' />} />

          <Route path='/notifications/settings' element={<NotificationsSettings />} />
          <Route path='/notifications/settings/edit' element={<NotificationsSettingsEdit />} />
          <Route path='/notifications/settings/global' element={<GlobalNotificationsSettings />} />

          <Route path='/program' element={<ProgramList />} />
          <Route path='/program/create' element={<ProgramEdit />} />
          <Route path='/program/:programId' element={<ProgramDetail />} />
          <Route path='/program/:programId/edit' element={<ProgramEdit />} />

          <Route path='/student' element={<PersonList type='student' />} />
          <Route path='/student/:personId' element={<PersonDetail type='student' />} />
          <Route path='/student/:personId/edit' element={<PersonEdit type='student' />} />
          <Route path='/student/:personId/guardian-messages' element={<PersonMessage type='student' />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/indicator' element={<ServiceList />} />
          <Route path='/indicator/create' element={<ServiceEdit />} />
          <Route path='/indicator/:indicatorId' element={<ServiceDetail />} />
          <Route path='/indicator/:indicatorId/edit' element={<ServiceEdit />} />

          <Route path='/session' element={<SessionList />} />
          <Route path='/session/create' element={<SessionEdit />} />
          <Route path='/session/:sessionId' element={<SessionDetail />} />
          <Route path='/session/:sessionId/edit' element={<SessionEdit />} />

          <Route path='/site' element={<SiteList />} />
          <Route path='/site/create' element={<SiteEdit />} />
          <Route path='/site/:siteId' element={<SiteDetail />} />
          <Route path='/site/:siteId/edit' element={<SiteEdit />} />

          <Route path='/type' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />
          {/* <Route path='/type/:entity/edit' element={<TypeEdit />} /> */}

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading connected={connected} loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadDeleteConfirmationModal />
        <RadConnection {...{ connected, setConnected }} />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
