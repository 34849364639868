import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadSelect } from '../common/RadSelect'
import { RadGrid } from '../common/RadGrid'
import { RadBox } from '../common/RadBox'
import { RadDivider } from '../common/RadDivider'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadPagination } from '../common/RadPagination'

export function SessionEdit () {
  const pageLength = 20
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { sessionId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: session } = useGet(sessionId ? `/api/session/${sessionId}` : null)
  const [formValues, setFormValues] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const [peopleFilterText, setPeopleFilterText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const { data: programs } = useGet('/api/program')
  const { data: programOptions } = useGet('/api/option/program?required=true')
  const { data: tierOptions } = useGet('/api/option/type?entity=tier&required=true')
  const { data: userInfo } = useGet('/api/user/current')
  const groupIds = searchParams.get('groupIds')
  const { data: people } = useGet(groupIds != null ? `/api/session/group-person?groupIds=${groupIds}` : null)
  const { data: groups } = useGet(groupIds != null ? `/api/group?groupIds=${groupIds}` : null)
  const studentId = searchParams.get('studentId')
  const { data: person } = useGet(studentId ? `/api/person/${studentId}?type=student` : null)
  const personOrganizationIds = formValues?.people.reduce((acc, curr) => {
    if (curr.schoolEnrollments) {
      curr.schoolEnrollments.forEach((x) => {
        if (!acc.includes(x.school.organizationId)) {
          acc.push(x.school.organizationId)
        }
      })
    }
    return acc
  }, [])
  const { data: userOptions } = useGet(personOrganizationIds != null ? `/api/option/user?search=${userFilterText}&organizationIds=${personOrganizationIds.join(',')}&required=true` : null)
  const create = usePost('/api/session', formValues, (resp) => { navigate(`/session/${resp.id}`) })
  const update = usePut(`/api/session/${sessionId}`, formValues, (resp) => { navigate(`/session/${sessionId}`) })

  useEffect(() => {
    const defaultFormValues = {
      date: moment().format('YYYY-MM-DD'),
      people: [],
      deliveries: [],
      administeredBy: userInfo?.id,
      owner: { name: userInfo?.name },
      description: groups != null ? groups.map((x) => x.name).join(' and ') + ' students' : ''
    }
    if (people != null) {
      const groupPeople = people.map((x) => {
        return {
          id: x.id,
          firstName: x.firstName,
          fullName: x.fullName,
          minutes: null,
          SessionPerson: {},
          schoolEnrollments: x.schoolEnrollments.map((y) => { return { school: y.school } })
        }
      }) ?? []
      defaultFormValues.people = groupPeople
    }
    if (person != null) {
      defaultFormValues.people =
        [
          {
            id: person.id,
            firstName: person.firstName,
            fullName: person.fullName,
            minutes: null,
            SessionPerson: {},
            schoolEnrollments: person.schoolEnrollments.map((y) => { return { school: y.school } })
          }
        ]
    }
    setFormValues(session ?? defaultFormValues)
  }, [session, userInfo, people, person, groups])

  async function saveChanges () {
    if (sessionId) { update() } else { create() }
  }

  if (formValues != null && programs != null && programOptions != null && tierOptions != null && userInfo != null) {
    const totalMinutes = formValues.deliveries.reduce((acc, curr) => acc + parseInt((curr.minutes ?? '').toString()), 0)
    const blockedPeopleCount = formValues?.people?.filter((x) => x.firstName == null && x.uuid == null).length
    const filteredPeople = formValues?.people?.filter((x) => x.firstName != null || x.uuid != null)
      .filter((x) =>
        peopleFilterText === '' ||
        `${x.SessionPerson.notes}`.toLowerCase().includes(peopleFilterText.toLowerCase()) ||
        `${x.fullName}`.toLowerCase().includes(peopleFilterText.toLowerCase())
      ) ?? []

    return (
      <RadAppLayout
        name={sessionId}
        contentHeader={
          <RadHeader variant='h1'>
            {sessionId == null ? 'New Session' : 'Edit Session'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={() => navigate(sessionId ? `/session/${sessionId}` : '/session')}>
                    Cancel
                  </RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
              header
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Date' field='date'>
                      <RadDatePicker
                        disabled={formValues.twilioConversationSid != null}
                        onChange={({ detail }) => setFormValues({ ...formValues, date: detail.value })}
                        value={formValues.date}
                        openCalendarAriaLabel={selectedDate =>
                          'Choose a Date' +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : '')}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Administered By' field='administeredBy'>
                      <RadSelect
                        ariaRequired
                        selectedOption={formValues.administeredBy ? { value: formValues.administeredBy.toString(), label: formValues.owner.name } : null}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, administeredBy: parseInt(detail.selectedOption.value), owner: { name: detail.selectedOption.label } })
                        }}
                        options={userOptions ?? []}
                        filteringType='manual'
                        onLoadItems={({ detail }) => {
                          setUserFilterText(detail.filteringText)
                        }}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a user'
                        empty={userFilterText ? 'No matches found' : null}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.deliveries.length + ')'}
                    >
                      Interventions
                    </RadHeader>
                  }
                >
                  {formValues.deliveries.map((item) =>
                    <DeliveryEdit
                      key={`delivery-${item.id},${item.uuid}`}
                      item={item}
                      programs={programs}
                      programOptions={programOptions}
                      tierOptions={tierOptions}
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                  )}
                  {formValues.deliveries.length === 0 && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No interventions</RadBox>}
                  {
                    formValues.twilioConversationSid == null &&
                      <RadButton
                        onClick={() => {
                          const deliveries = formValues.deliveries
                          const delivery = { uuid: uuidv4(), skills: [], serviceDelivery: { service: {} }, tierId: tierOptions.find((x) => x.label === '2')?.value }
                          delivery.programId = userInfo.roles.find((x) => x.primaryProgramId != null)?.primaryProgramId
                          deliveries.push(delivery)
                          setFormValues({ ...formValues, deliveries })
                        }}
                      >
                        Add new intervention
                      </RadButton>
                  }
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={`(${filteredPeople.length})`}
                    >
                      Students
                    </RadHeader>
                  }
                >
                  <RadBox padding={{ bottom: 'l' }}>
                    {`${blockedPeopleCount > 0 ? `* This session has ${blockedPeopleCount} ${blockedPeopleCount > 1 ? 'students' : 'student'} students you do not have permission to view` : ''}`}
                  </RadBox>
                  <RadGrid
                    gridDefinition={isMobile
                      ? [{ colspan: 12 }, { colspan: 12 }]
                      : [{ colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 4 } }]}
                  >
                    {
                      formValues.twilioConversationSid == null &&
                        <RadInput
                          placeholder='Search'
                          ariaLabel='Search'
                          onChange={({ detail }) => {
                            setCurrentPageIndex(1)
                            setPeopleFilterText(detail.value)
                          }}
                          value={peopleFilterText}
                          type='search'
                        />
                    }
                    {!isMobile && <div />}
                    {
                      formValues.twilioConversationSid == null &&
                        <RadPagination
                          currentPageIndex={currentPageIndex}
                          pagesCount={Math.ceil(filteredPeople.length / pageLength)}
                          onChange={({ detail }) => {
                            searchParams.set('page', detail.currentPageIndex)
                            setSearchParams(searchParams)
                            setCurrentPageIndex(detail.currentPageIndex)
                          }}
                          ariaLabels={{
                            nextPageLabel: 'Next page',
                            previousPageLabel: 'Previous page',
                            pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                          }}
                        />
                    }
                  </RadGrid>
                  <br />
                  {
                    filteredPeople
                      .slice((currentPageIndex - 1) * pageLength, currentPageIndex * pageLength)
                      .map((item) =>
                        <PersonEdit
                          key={`person-${item.id},${item.uuid}`}
                          item={formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          totalMinutes={totalMinutes}
                        />)
                  }
                  {formValues.people.length === 0 && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No students</RadBox>}
                  {filteredPeople.length === 0 && formValues.people.length > 0 && peopleFilterText !== '' && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No matching students</RadBox>}
                  {formValues.twilioConversationSid == null &&
                    <RadButton
                      onClick={() => {
                        const people = formValues.people
                        people.push({ uuid: uuidv4(), minutes: null, SessionPerson: {} })
                        setFormValues({ ...formValues, people })
                        const lastPage = Math.ceil((filteredPeople.length + 1) / pageLength)
                        setCurrentPageIndex(lastPage)
                      }}
                    >
                      Add new student
                    </RadButton>}
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function DeliveryEdit ({ item, programs, programOptions, tierOptions, formValues, setFormValues }) {
  const program = useMemo(
    () => programs?.find((x) => x.id === item.programId),
    [item.programId, programs]
  )

  const service = useMemo(
    () => program?.services?.find((x) => x.id === item.serviceDelivery.serviceId),
    [item.serviceDelivery.serviceId, program]
  )

  const serviceOptions = useMemo(() => {
    return !program
      ? []
      : program.services
        .filter(s =>
          formValues.twilioConversationSid == null ||
          s.deliveries.some(d => d.name === 'Communication Home')
        )
        .map(x => ({ value: x.id.toString(), label: x.name }))
  }, [program, formValues.twilioConversationSid])

  const deliveryOptions = useMemo(() => {
    return !program || !service
      ? []
      : service.deliveries
        .filter(d => formValues.twilioConversationSid == null || d.name === 'Communication Home')
        .map(x => ({ value: x.id.toString(), label: x.name }))
  }, [program, service, formValues.twilioConversationSid])

  const skillOptions = useMemo(() => {
    return !program || !service
      ? []
      : service.skills.map(x => ({ value: x.id.toString(), label: x.name }))
  }, [program, service])

  useEffect(() => {
    if (formValues.twilioConversationSid == null || deliveryOptions.length < 1) return

    const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))

    delivery.serviceDeliveryId = parseInt(deliveryOptions[0].value)
  }, [item.serviceDelivery.serviceId, formValues.deliveries, deliveryOptions, item.uuid, item.id, formValues.twilioConversationSid])

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 2 } }
        ]}
      >
        <RadFormField label='Program' field={`delivery.${item.id || item.uuid}.programId`}>
          <RadSelect
            ariaRequired
            selectedOption={item.programId ? programOptions.find((o) => o.value === item.programId.toString()) : null}
            onChange={({ detail }) => {
              const deliveries = formValues.deliveries
              const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              delivery.programId = parseInt(detail.selectedOption.value)
              delivery.name = detail.selectedOption.label
              delivery.serviceDeliveryId = null
              delivery.serviceDelivery.serviceId = null
              delivery.serviceDelivery.service = {}
              delivery.skills = []
              setFormValues({ ...formValues, deliveries })
            }}
            options={programOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a program'
          />
        </RadFormField>
        <RadFormField label='Tier' field={`delivery.${item.id || item.uuid}.tierId`}>
          <RadSelect
            ariaRequired
            selectedOption={item.tierId ? tierOptions.find((o) => o.value === item.tierId.toString()) : null}
            onChange={({ detail }) => {
              const deliveries = formValues.deliveries
              const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              delivery.tierId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, deliveries })
            }}
            options={tierOptions}
            enteredTextLabel={value => value}
            placeholder='Choose a tier'
            selectedAriaLabel='Selected'
          />
        </RadFormField>
        <RadFormField label='Indicator' field={`delivery.${item.id || item.uuid}.service`}>
          <RadSpaceBetween size='xs'>
            <RadSelect
              ariaRequired
              selectedOption={item.serviceDelivery.serviceId ? serviceOptions.find((x) => x.value === item.serviceDelivery.serviceId.toString()) : null}
              onChange={({ detail }) => {
                const deliveries = formValues.deliveries
                const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                delivery.serviceDelivery.serviceId = parseInt(detail.selectedOption.value)
                delivery.serviceDelivery.service.name = detail.selectedOption.label
                setFormValues({ ...formValues, deliveries })
              }}
              filteringType='auto'
              options={serviceOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose an indicator'
              disabled={!item.programId}
            />
            {
              formValues.twilioConversationSid != null &&
                <RadBox variant='small'>
                  Only Indicators with a 'Communication Home' Intervention are displayed here.
                </RadBox>
            }
          </RadSpaceBetween>
        </RadFormField>
        <RadFormField label='Intervention' field={`delivery.${item.id || item.uuid}.serviceDeliveryId`}>
          <RadSelect
            ariaRequired
            selectedOption={
              formValues.twilioConversationSid == null
                ? item.serviceDeliveryId
                  ? deliveryOptions.find((x) => x.value === item.serviceDeliveryId.toString())
                  : null
                : deliveryOptions[0]
            }
            onChange={({ detail }) => {
              const deliveries = formValues.deliveries
              const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              delivery.serviceDeliveryId = parseInt(detail.selectedOption.value)
              delivery.name = detail.selectedOption.label
              setFormValues({ ...formValues, deliveries })
            }}
            filteringType='auto'
            options={deliveryOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose an intervention'
            disabled={formValues.twilioConversationSid != null || !item.serviceDelivery.serviceId}
          />
        </RadFormField>
        <RadFormField label='Minutes' field={`delivery.${item.id || item.uuid}.minutes`}>
          <RadInput
            inputMode='numeric'
            value={item.minutes}
            placeholder='Enter minutes'
            onChange={({ detail }) => {
              const deliveries = formValues.deliveries
              const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              delivery.minutes = detail.value
              setFormValues({ ...formValues, deliveries })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadSpaceBetween label='Skills' direction='horizontal' size='l'>
        {
          skillOptions.map((x) => {
            const deliveries = [...formValues.deliveries]
            const delivery = formValues.deliveries.find((y) => y.uuid === (item.uuid ?? '') || y.id === (item.id ?? ''))
            const skill = delivery.skills.filter((y) => y.serviceSkillId.toString() === x.value).length > 0
            return (
              <RadCheckbox
                key={`${item.id},${x.value}`}
                checked={skill}
                onChange={({ detail }) => {
                  if (skill) {
                    let skills = delivery.skills
                    skills = skills.filter((y) => y.serviceSkillId.toString() !== x.value)
                    delivery.skills = skills
                    setFormValues({ ...formValues, deliveries })
                  } else {
                    delivery.skills.push({ serviceSkillId: parseInt(x.value) })
                    setFormValues({ ...formValues, deliveries })
                  }
                }}
              >
                {x.label}
              </RadCheckbox>
            )
          })
        }
      </RadSpaceBetween>

      {
        formValues.twilioConversationSid == null &&
          <RadBox float='right'>
            <RadButton
              onClick={() => {
                let deliveries = [...formValues.deliveries]
                deliveries = deliveries.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
                setFormValues({ ...formValues, deliveries })
              }}
            >
              Remove
            </RadButton>
          </RadBox>
      }
      {formValues.twilioConversationSid == null && <RadDivider />}
    </RadSpaceBetween>
  )
}

function PersonEdit ({ item, formValues, setFormValues, totalMinutes }) {
  const [personFilterText, setPersonFilterText] = useState('')
  const { data: personOptions } = useGet([
    '/api/person?type=student&options=true',
    `search=${personFilterText}&limit=20`
  ].join('&'))
  const filteredOptions = personOptions?.filter((option) => !formValues.people.map((x) => x.id?.toString()).includes(option.value))
  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 7 } }
        ]}
      >
        <RadFormField label='Student' field={`people.${item.id || item.uuid}.id`}>
          <RadSelect
            disabled={formValues.twilioConversationSid != null}
            selectedOption={item.id ? { value: item.id.toString(), label: item.fullName } : null}
            options={filteredOptions}
            filteringType='manual'
            onLoadItems={({ detail }) => {
              setPersonFilterText(detail.filteringText)
            }}
            empty={personFilterText ? 'No matches found' : null}
            placeholder='Choose a student'
            onChange={({ detail }) => {
              const people = formValues.people
              const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              person.id = parseInt(detail.selectedOption.value)
              person.fullName = detail.selectedOption.label
              person.schoolEnrollments = [{ school: { organizationId: detail.selectedOption.organizationId } }]
              setFormValues({ ...formValues, people })
            }}
            expandToViewport
          />
        </RadFormField>
        <RadFormField label='Minutes' field={`people.${item.id || item.uuid}.minutes`}>
          <RadInput
            value={item.SessionPerson.minutes ?? (!isNaN(totalMinutes) && totalMinutes > 0 ? totalMinutes : null)}
            placeholder='Enter minutes'
            inputMode='numeric'
            onChange={({ detail }) => {
              const people = formValues.people
              const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              if (!isNaN(totalMinutes) && totalMinutes.toString() === detail.value) {
                person.SessionPerson.minutes = null
              } else {
                person.SessionPerson.minutes = detail.value
              }
              setFormValues({ ...formValues, people })
            }}
          />
        </RadFormField>
        <RadFormField label='Notes' field={`people.${item.id || item.uuid}.notes`}>
          <RadTextarea
            value={item.SessionPerson.notes}
            placeholder='Enter notes'
            rows={(item.SessionPerson.notes ?? '') === '' ? 1 : 3}
            onChange={({ detail }) => {
              const people = formValues.people
              const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
              person.SessionPerson.notes = detail.value
              setFormValues({ ...formValues, people })
            }}
          />
        </RadFormField>
      </RadGrid>
      {
        formValues.twilioConversationSid == null &&
          <RadBox float='right'>
            <RadButton
              onClick={() => {
                let people = [...formValues.people]
                people = people.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
                setFormValues({ ...formValues, people })
              }}
            >
              Remove
            </RadButton>
          </RadBox>
      }
      {formValues.twilioConversationSid == null && <RadDivider />}
    </RadSpaceBetween>
  )
}
