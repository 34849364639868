import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadModal } from './RadModal'
import { RadHeader } from './RadHeader'
import { RadColumnLayout } from './RadColumnLayout'
import { RadBox } from './RadBox'
import { RadIcon } from './RadIcon'
import { RadLink } from './RadLink'
import { RadSpaceBetween } from './RadSpaceBetween'
import { usePut } from '../hooks/usePut'
import { formatDate } from './utilities'
import { usePost } from '../hooks/usePost'
import { RadButton } from './RadButton'

// Set it to `true` temporarily if you need to test notifications locally
const pollForever = process.env.NODE_ENV !== 'development'

export function RadNotificationInbox ({ onUpdated, show, setShow, stopNotificationsHref }) {
  const [searchParams] = useSearchParams()
  const [readIds, setReadIds] = useState([])
  const [deleteIds, setDeleteIds] = useState([])
  const [poll, setPoll] = useState(1)
  const [notifications, setNotifications] = useState([])
  const { data: allNotifications } = useGet(`/api/notification?poll=${poll}`, true)
  const markRead = usePut('/api/notification', { ids: readIds }, (resp) => { setReadIds([]) }, true)
  const remove = usePost('/api/notification/delete', { ids: deleteIds }, (resp) => { }, true)

  const matchPersonId = window.location.href.match(/student\/(\d+)/)
  const personId = matchPersonId != null ? matchPersonId[1] : null
  const conversation = searchParams.get('conversation')
  const notificationFilter = personId != null && conversation != null ? new RegExp(`/student/${personId}/guardian-messages\\?conversation=${conversation}`) : null

  useEffect(() => {
    if (allNotifications != null) {
      const idsToDelete = allNotifications
        .filter(
          n => notificationFilter != null && n.unread && notificationFilter.test(n.href)
        )
        .map(n => n.id)

      setNotifications(allNotifications.filter(n => !idsToDelete.includes(n.id)))
      setDeleteIds(idsToDelete)
    }

    const interval = pollForever ? setInterval(() => { setPoll(p => p + 1) }, 10000) : null

    return () => clearInterval(interval)
  }, [allNotifications, stopNotificationsHref])

  useEffect(
    () => onUpdated(notifications.some(n => n.unread)),
    [notifications, onUpdated]
  )

  useEffect(
    () => {
      if (deleteIds.length > 0) {
        remove()
        setDeleteIds([])
      }
    },
    [deleteIds, remove]
  )

  useEffect(
    () => { if (readIds.length > 0) markRead() },
    [readIds, markRead]
  )

  useEffect(() => { if (show) { setPoll(p => p + 1) } }, [show])

  return (
    show &&
      <RadModal
        onDismiss={() => setShow(false)}
        visible
        header={
          <RadHeader
            variant='h2'
            actions={
              <RadButton
                formAction='none'
                href='/notifications/settings'
                variant='link'
                iconName='settings'
                iconAlt='Notification settings'
              />
            }
          >
            Notifications
          </RadHeader>
        }
      >
        {
          notifications.length > 0 &&
            <RadBox style={({ overflow: 'auto' })} variant='div'>
              <RadColumnLayout borders='horizontal'>
                {
                  notifications.map((notification, i) => (
                    <RadBox key={i}>
                      <RadBox float='right' textAlign='right'>
                        {
                          notification.unread &&
                            <RadButton
                              variant='icon'
                              iconName='check'
                              iconAlt='Mark notification as read'
                              formAction='none'
                              onClick={() => {
                                setReadIds(r => [...r, notification.id])
                                setNotifications(n => n.map(x => {
                                  if (x.id === notification.id) {
                                    x.unread = false
                                  }
                                  return x
                                }))
                              }}
                            />
                        }
                        <RadButton
                          variant='icon'
                          iconName='remove'
                          iconAlt='Delete notification'
                          formAction='none'
                          onClick={() => {
                            setDeleteIds(r => [...r, notification.id])
                            setNotifications(n => n.filter(x => x.id !== notification.id))
                          }}
                        />
                      </RadBox>
                      <RadSpaceBetween>
                        <RadSpaceBetween direction='horizontal' size='xs'>
                          <RadIcon
                            name={notification.icon}
                            variant={notification.unread ? 'normal' : 'subtle'}
                          />
                          <RadBox
                            variant='h4'
                            color={notification.unread ? 'text-label' : 'text-status-inactive'}
                          >
                            {notificationTitle(notification)}
                          </RadBox>
                        </RadSpaceBetween>
                        <RadBox variant='small' color='text-status-inactive' fontSize='body-s'>
                          {formatDate(notification.createdAt, true)}
                        </RadBox>
                        <RadBox variant='p'>
                          <RadLink
                            variant={notification.unread ? 'primary' : 'secondary'}
                            href={notification.href}
                            onFollow={() => setReadIds(r => [...r, notification.id])}
                          >
                            {notification.description}
                          </RadLink>
                        </RadBox>
                      </RadSpaceBetween>
                    </RadBox>
                  ))
                }
              </RadColumnLayout>
            </RadBox>
        }
        {
          notifications.length === 0 &&
            <RadBox variant='div' textAlign='center'>
              <RadBox variant='p' color='text-status-inactive'>
                <RadIcon name='check' variant='normal' /> No notifications
              </RadBox>
            </RadBox>
        }
      </RadModal>
  )
}

function notificationTitle ({ type, unread }) {
  return type
    .split('_')
    .filter(x => unread || x !== 'new')
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ') + (unread ? ' *' : '')
}
