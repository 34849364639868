import React from 'react'
import PropertyFilter from '@cloudscape-design/components/property-filter'

export function RadPropertyFilter ({ collectionName, i18nStrings, ...rest }) {
  i18nStrings = i18nStrings ?? {}
  i18nStrings.clearFiltersText = 'Clear'
  i18nStrings.cancelActionText = 'Cancel'
  i18nStrings.applyActionText = 'Apply'
  i18nStrings.operationAndText = 'and'
  i18nStrings.operationOrText = 'or'
  i18nStrings.operatorContainsText = 'contains'
  i18nStrings.operatorDoesNotContainText = 'does not contain'
  i18nStrings.filteringAriaLabel = `Search and filter ${collectionName ?? 'collection'}`
  i18nStrings.filteringPlaceholder = `Search and filter ${collectionName ?? 'collection'}`

  return (
    <PropertyFilter i18nStrings={i18nStrings} {...rest} />
  )
}
