import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadButton } from '../common/RadButton'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadForm } from '../common/RadForm'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadSelect } from '../common/RadSelect'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'

export function GroupEdit () {
  const navigate = useNavigate()
  const { groupId } = useParams()
  const { data: group } = useGet(groupId ? `/api/group/${groupId}` : null)
  const [formValues, setFormValues] = useState()
  const { data: parentOptions } = useGet(`/api/option/group${groupId !== undefined ? '?exclude=' + groupId : ''}`)
  const [personFilterText, setPersonFilterText] = useState('')
  const { data: personOptions } = useGet([
    '/api/person?type=student&options=true',
    `search=${personFilterText}`,
    'limit=20'
  ].join('&'))
  const { data: typeOptions } = useGet('/api/option/type?entity=group&hideProtected=true')

  useEffect(() => {
    const defaultFormValues = { people: [] }
    setFormValues(group ?? defaultFormValues)
  }, [group])

  const create = usePost('/api/group', formValues, (resp) => { navigate(`/group/${resp.id}`) })
  const update = usePut(`/api/group/${groupId}`, formValues, (resp) => { navigate(`/group/${groupId}`) })

  async function saveChanges () {
    if (formValues.id) { update() } else { create() }
  }

  async function cancel () {
    if (groupId) {
      navigate(`/group/${group.id}`)
    } else {
      navigate('/group')
    }
  }

  if (formValues && parentOptions && typeOptions) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>Edit Group</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadContainer>
                <RadSpaceBetween size='l'>
                  <RadFormField label='Name' field='name'>
                    <RadInput
                      ariaRequired
                      placeholder='Enter name'
                      disabled={formValues.isProtected}
                      value={formValues.name ?? ''}
                      onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                    />
                  </RadFormField>
                  <RadFormField label='Type' field='typeId'>
                    <RadSelect
                      selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId.toString()) : { value: '', label: '-' }}
                      onChange={({ detail }) => {
                        if (detail.selectedOption.value !== '') {
                          setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                        } else {
                          setFormValues({ ...formValues, typeId: null })
                        }
                      }}
                      options={typeOptions}
                      selectedAriaLabel='Selected'
                      empty='No matches found'
                    />
                  </RadFormField>
                  <RadFormField label='Description' field='description'>
                    <RadTextarea
                      placeholder='Enter description'
                      value={formValues.description ?? ''}
                      onChange={event => setFormValues({ ...formValues, description: event.detail.value })}
                    />
                  </RadFormField>
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const people = formValues.people
                      people.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, people })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const people = [...formValues.people]
                      people.splice(itemIndex, 1)
                      setFormValues({ ...formValues, people })
                    }}
                    items={formValues.people}
                    addButtonText='Add new student'
                    definition={[
                      {
                        label: 'Student',
                        control: item => {
                          const filteredOptions = personOptions?.filter((x) => !formValues.people.map((y) => y.id?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`people.${item.id || item.uuid}.id`}>
                              <RadSelect
                                placeholder='Choose a student'
                                selectedOption={item.id ? { value: item.id.toString(), label: item.fullName } : null}
                                options={filteredOptions}
                                filteringType='manual'
                                onLoadItems={({ detail }) => {
                                  setPersonFilterText(detail.filteringText)
                                }}
                                empty={personFilterText ? 'No matches found' : null}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  person.id = parseInt(detail.selectedOption.value)
                                  person.fullName = detail.selectedOption.label
                                  setFormValues({ ...formValues, people })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No students associated with this group.'
                  />
                </RadSpaceBetween>
              </RadContainer>
            </RadForm>
          </form>
        }
      />
    )
  }
}
