import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadContainer } from '../common/RadContainer'
import { RadGrid } from '../common/RadGrid'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadButton } from '../common/RadButton'
import { RadFormField } from '../common/RadFormField'
import { RadSelect } from '../common/RadSelect'
import { stateList } from '../common/stateList'

export function SiteEdit () {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { siteId } = useParams()
  const { data: site } = useGet(siteId ? `/api/site/${siteId}` : null)
  const [formValues, setFormValues] = useState()
  const { data: typeOptions } = useGet('/api/option/type?entity=site')
  const { data: organizations } = useGet('/api/organization')
  const parentOptions = organizations?.map((x) => { return { value: x.id.toString(), label: x.name } }) ?? []
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: '', label: '-' })
  const create = usePost('/api/site', formValues, (resp) => { navigate(`/site/${resp.id}`) })
  const update = usePut(`/api/site/${siteId}`, formValues, (resp) => { navigate(`/site/${siteId}`) })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(site ?? defaultFormValues)
  }, [site])

  async function cancel () {
    if (siteId) {
      navigate(`/site/${siteId}`)
    } else {
      navigate('/site')
    }
  }

  async function saveChanges () {
    if (siteId) { update() } else { create() }
  }

  if (formValues && typeOptions && organizations && parentOptions) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>Edit Site</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Parent Organization' field='parentId'>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={parentOptions.find(x => x.value === (formValues.parentId?.toString() ?? ''))}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, parentId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, parentId: null })
                          }
                        }}
                        options={parentOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Type' field='typeId'>
                      <RadSelect
                        selectedOption={typeOptions.find(x => x.value === (formValues.typeId?.toString() ?? ''))}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Phone' field='phone'>
                      <RadInput
                        placeholder='Enter phone number'
                        value={formValues.phone ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Address
                    </RadHeader>
                  }
                >
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 12, xxs: 6 } }
                    ]}
                  >
                    <RadFormField label='Address' field='line1'>
                      <RadInput
                        placeholder='Enter line 1'
                        value={formValues.address?.line1 ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField label={isMobile ? null : '.'} field='line2'>
                      <RadInput
                        placeholder='Enter line 2'
                        value={formValues.address?.line2 ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 6, xxs: 3 } },
                      { colspan: { default: 6, xxs: 3 } }
                    ]}
                  >
                    <RadFormField field='city'>
                      <RadInput
                        placeholder='Enter city'
                        value={formValues.address?.city ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='state'>
                      <RadSelect
                        filteringType='auto'
                        placeholder='Choose a state'
                        options={stateListOptions}
                        selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
                        onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='zip'>
                      <RadInput
                        placeholder='Enter ZIP code'
                        inputMode='numeric'
                        value={formValues.address?.zip ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
