import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadPagination } from '../common/RadPagination'
import { RadButton } from '../common/RadButton'
import { RadSelect } from '../common/RadSelect'
import { RadGrid } from '../common/RadGrid'
import { numberWithCommas } from '../common/utilities'

export function GroupList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedUserOption, setSelectedUserOption] = useState()
  const [selectedTypeOption, setSelectedTypeOption] = useState()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [userFilterText, setUserFilterText] = useState('')
  const { data: userInfo } = useGet('/api/user/current')
  const { data: groups, count } = useGet(selectedUserOption != null && selectedTypeOption != null ? `/api/group?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}&createdById=${selectedUserOption?.value !== '0' ? selectedUserOption?.value : ''}&typeId=${selectedTypeOption?.value !== '0' ? selectedTypeOption?.value : ''}` : null)
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}&required=true`)
  const { data: typeOptions } = useGet('/api/option/type?entity=group&required=true&any=true')
  const { data: types } = useGet('/api/type')
  const { data: createdBy } = useGet(searchParams.get('createdBy') !== null && parseInt(searchParams.get('createdBy')) > 1 ? `/api/user/${searchParams.get('createdBy')}` : null)

  useEffect(() => {
    if (userInfo != null && searchParams.get('createdBy') == null) {
      setSelectedUserOption({ value: userInfo.id.toString(), label: userInfo.name })
    }
    if (selectedUserOption == null && searchParams.get('createdBy') != null && createdBy != null) {
      setSelectedUserOption({ value: createdBy.id.toString(), label: createdBy.name.replace('Migration', 'Auto-generated') })
    }
    if (selectedUserOption == null && searchParams.get('createdBy') === '0') {
      setSelectedUserOption({ value: '0', label: 'Any creator' })
    }
    if (selectedUserOption == null && searchParams.get('createdBy') === '-1') {
      setSelectedUserOption({ value: '-1', label: 'User-generated' })
    }
    if (selectedUserOption == null && searchParams.get('createdBy') === '1') {
      setSelectedUserOption({ value: '1', label: 'Auto-generated' })
    }
    if (selectedTypeOption == null) {
      if (searchParams.get('typeId') != null && types != null) {
        const type = types.find((x) => x.id.toString() === searchParams.get('typeId'))
        if (type != null) {
          setSelectedTypeOption({ value: type.id.toString(), label: type.name })
        }
      }
      if (searchParams.get('typeId') == null) {
        setSelectedTypeOption({ value: '0', label: 'Any type' })
      }
    }
  }, [userInfo, createdBy, types])

  useEffect(() => {
    if (userOptions != null) {
      userOptions.unshift({ value: '-1', label: 'User-generated' })
      userOptions.unshift({ value: '1', label: 'Auto-generated' })
      userOptions.unshift({ value: '0', label: 'Any creator' })
    }
  }, [userOptions])

  useEffect(() => {
    const items = []
    selectedItems.forEach((x) => {
      items.push(x)
    })
    if (groups != null) {
      groups.forEach((x) => {
        if (selectedItems.find((y) => y.id === x.id) == null) {
          items.push(x)
        }
      })
    }
    setItems(items)
  }, [groups])

  function createSession () {
    navigate(`/session/create?groupIds=${selectedItems.map((x) => x.id.toString()).join(',')}`)
  }

  if (groups != null && selectedUserOption != null && selectedTypeOption != null && types != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/group/create')}>Create</RadButton>
                <RadButton disabled={selectedItems.length === 0} onClick={createSession}>Create Session</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + numberWithCommas(count) + ')'}
          >
            Groups
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/group/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'description',
                  content: item => item.description
                },
                {
                  id: 'type',
                  header: 'Type',
                  content: item => item.typeName ?? '-'
                },
                {
                  id: 'studentCount',
                  header: 'Students',
                  content: item => item.studentCount
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={items}
            variant='full-page'
            selectionType='multi'
            trackBy='id'
            onSelectionChange={({ detail }) => {
              setSelectedItems(detail.selectedItems)
            }}
            selectedItems={selectedItems}
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xxs: 6 } },
                  { colspan: { default: 12, xxs: 3 } },
                  { colspan: { default: 12, xxs: 3 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search groups'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={selectedTypeOption}
                  onChange={({ detail }) => {
                    const selectedType = types.find((x) => x.id.toString() === detail.selectedOption.value)
                    if (selectedType?.isProtected) {
                      setSelectedUserOption({ value: '1', label: 'Auto-generated' })
                      searchParams.set('createdBy', '1')
                    } else {
                      if (selectedUserOption?.value === '1') {
                        setSelectedUserOption({ value: '-1', label: 'User-generated' })
                        searchParams.set('createdBy', '-1')
                      }
                    }
                    setSelectedTypeOption(detail.selectedOption)
                    if (detail.selectedOption.value !== '') {
                      searchParams.set('typeId', detail.selectedOption.value)
                    } else {
                      searchParams.delete('typeId')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={typeOptions ?? []}
                  filteringType='auto'
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Select type'
                />
                <RadSelect
                  selectedOption={selectedUserOption}
                  onChange={({ detail }) => {
                    const selectedType = types.find((x) => x.id.toString() === selectedTypeOption.value)
                    if (selectedType?.isProtected && (detail.selectedOption.value !== '' || detail.selectedOption.value !== '1')) {
                      setSelectedTypeOption({ value: '', label: 'Any type' })
                      searchParams.delete('typeId')
                    }
                    if (!selectedType?.isProtected && detail.selectedOption.value === '1') {
                      setSelectedTypeOption({ value: '', label: 'Any type' })
                      searchParams.delete('typeId')
                    }
                    setSelectedUserOption(detail.selectedOption)
                    if (detail.selectedOption.value !== '' && detail.selectedOption.value !== userInfo.id.toString()) {
                      searchParams.set('createdBy', detail.selectedOption.value)
                    } else {
                      searchParams.delete('createdBy')
                    }
                    setSearchParams(searchParams)
                  }}
                  options={userOptions ?? []}
                  filteringType='manual'
                  onLoadItems={({ detail }) => setUserFilterText(detail.filteringText)}
                  enteredTextLabel={value => value}
                  selectedAriaLabel='Selected'
                  placeholder='Select creator'
                  empty={userFilterText ? 'No matches found' : null}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
        }
      />
    )
  }
}
