import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function ProgramList ({ selected }) {
  const navigate = useNavigate()
  const { data: programs } = useGet('/api/program')

  if (programs) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/program/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + programs.length + ')'}
          >
            Programs
          </RadHeader>
        }
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionGroupLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <RadLink fontSize='heading-m' href={`/program/${item.id}`}>{item.name}</RadLink>
              ),
              sections: [
                {
                  id: 'typeId',
                  header: 'Type',
                  content: item => item.type?.name ?? '-'
                },
                {
                  id: 'description',
                  header: 'Description',
                  content: item => item.description ?? '-'
                },
                {
                  id: 'services',
                  header: 'Indicators',
                  content: item => item.services.length > 0
                    ? <>{item.services.map((x) => { return (<div key={x.id}><RadLink href={`/indicator/${x.id}`}>{x.name}</RadLink></div>) })}</>
                    : <div>-</div>
                },
                {
                  id: 'goals',
                  header: 'Goals',
                  content: item => item.goals.length > 0
                    ? <>{item.goals.map((x) => <div key={'goal-' + x.id}>{x.name}</div>)}</>
                    : <div>-</div>
                },
                {
                  id: 'exitReasons',
                  header: 'Exit Reasons',
                  content: item => item.exitReasons.length > 0
                    ? <>{item.exitReasons.map((x) => <div key={'exit_reason-' + x.id}>{x.name}</div>)}</>
                    : <div>-</div>
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={programs ?? []}
            variant='full-page'
          />
        }
      />
    )
  }
}
