import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadButton } from '../common/RadButton'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadTextarea } from '../common/RadTextarea'
import { RadSelect } from '../common/RadSelect'

export function ServiceEdit () {
  const navigate = useNavigate()
  const { indicatorId } = useParams()
  const { data: service } = useGet(indicatorId ? `/api/service/${indicatorId}` : '')
  const { data: programOptions } = useGet('/api/option/program')
  const [formValues, setFormValues] = useState()
  const create = usePost('/api/service', formValues, (resp) => { navigate(`/indicator/${resp.id}`) })
  const update = usePut(`/api/service/${indicatorId}`, formValues, (resp) => { navigate(`/indicator/${indicatorId}`) })

  useEffect(() => {
    const defaultFormValues = { deliveries: [], skills: [], programs: [] }
    setFormValues(service ?? defaultFormValues)
  }, [service])

  async function cancel () {
    if (indicatorId) {
      navigate(`/indicator/${indicatorId}`)
    } else {
      navigate('/indicator')
    }
  }

  async function saveChanges () {
    if (indicatorId) { update() } else { create() }
  }

  if (formValues && programOptions) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{formValues.id ? 'Edit' : 'New'} Indicator</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        value={formValues.name ?? ''}
                        onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description ?? ''}
                        onChange={event => setFormValues({ ...formValues, description: event.detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Interventions
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const delivery = formValues.deliveries
                      delivery.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, deliveries: delivery })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const delivery = [...formValues.deliveries]
                      delivery.splice(itemIndex, 1)
                      setFormValues({ ...formValues, deliveries: delivery })
                    }}
                    items={formValues.deliveries}
                    addButtonText='Add intervention'
                    definition={[
                      {
                        label: 'Intervention',
                        control: item =>
                          <RadFormField field={`delivery.${item.id || item.uuid}.name`}>
                            <RadInput
                              ariaRequired
                              placeholder='Enter intervention'
                              value={item.name ?? ''}
                              onChange={({ detail }) => {
                                const deliveries = formValues.deliveries
                                const delivery = formValues.deliveries.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                delivery.name = detail.value
                                setFormValues({ ...formValues, deliveries })
                              }}
                            />
                          </RadFormField>
                      }
                    ]}
                    removeButtonText='Remove'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Skills
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const skill = formValues.skills
                      skill.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, skills: skill })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const skill = [...formValues.skills]
                      skill.splice(itemIndex, 1)
                      setFormValues({ ...formValues, skills: skill })
                    }}
                    items={formValues.skills}
                    addButtonText='Add skill'
                    definition={[
                      {
                        label: 'Skill',
                        control: item =>
                          <RadFormField field={`skill.${item.id || item.uuid}.name`}>
                            <RadInput
                              ariaRequired
                              placeholder='Enter skill'
                              value={item.name ?? ''}
                              onChange={({ detail }) => {
                                const skills = formValues.skills
                                const skill = formValues.skills.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                skill.name = detail.value
                                setFormValues({ ...formValues, skills })
                              }}
                            />
                          </RadFormField>
                      }
                    ]}
                    removeButtonText='Remove'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Programs
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const programs = formValues.programs
                      programs.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, programs })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const programs = [...formValues.programs]
                      programs.splice(itemIndex, 1)
                      setFormValues({ ...formValues, programs })
                    }}
                    items={formValues.programs}
                    addButtonText='Add program'
                    definition={[
                      {
                        label: 'Program',
                        control: item => {
                          const filteredOptions = (programOptions ?? []).filter((x) => !formValues.programs.map((y) => y.id?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`program.${item.id || item.uuid}.id`}>
                              <RadSelect
                                selectedOption={item.id ? { value: item.id.toString(), label: item.name } : null}
                                onChange={({ detail }) => {
                                  const programs = formValues.programs
                                  const program = formValues.programs.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  program.id = parseInt(detail.selectedOption.value)
                                  program.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, programs })
                                }}
                                options={filteredOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a program'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
