import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatNumber } from '../../common/utilities'
import { RadSpaceBetween } from '../../common/RadSpaceBetween'
import { RadExpandableSection } from '../../common/RadExpandableSection'
import { RadBox } from '../../common/RadBox'
import { RadButtonDropdown } from '../../common/RadButtonDropdown'
import { useDelete } from '../../hooks/useDelete'
import { useConfirm } from '../../hooks/useConfirm'
import { LogSessionFromMessage } from './LogSessionFromMessage'
import { MessageThread } from './MessageThread'
import { ConversationForm } from './ConversationForm'
import { RadInput } from '../../common/RadInput'
import { RadButton } from '../../common/RadButton'
import { usePut } from '../../hooks/usePut'
import { RadModal } from '../../common/RadModal'
import { RadHeader } from '../../common/RadHeader'
import { RadFormField } from '../../common/RadFormField'
import { useGet } from '../../hooks/useGet'

export function ConversationsList ({ personId, conversations, setWarning, connected }) {
  const { data: userInfo } = useGet('/api/user/current')
  const [searchParams, setSearchParams] = useSearchParams()
  const conversationSid = searchParams.get('conversation')
  const conversation = conversations
    .filter(it => it != null && conversationSid != null)
    .find(it => it.sid === conversationSid) ?? null

  useEffect(() => {
    if (!connected) return

    const code = `Conversation (${conversationSid}) does not exist.`

    if (conversation != null) {
      setWarning(w => w?.code !== code ? w : null)
    }

    if (conversationSid != null && conversation == null) {
      setWarning({ code })
    }
  }, [connected, conversation, conversationSid, setWarning, setSearchParams])

  return (
    <RadSpaceBetween size='l' direction='vertical'>
      {conversations.map(conversation => (
        <Conversation
          key={conversation?.sid ?? 'new'}
          personId={personId}
          userInfo={userInfo}
          conversation={conversation}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ))}
    </RadSpaceBetween>
  )
}

function Conversation ({ personId, userInfo, conversation, searchParams, setSearchParams }) {
  const navigate = useNavigate()
  const [recipients, setRecipients] = useState([])
  const [messages, setMessages] = useState([])
  const [selecting, setSelecting] = useState(false)
  const [selectedMessages, setSelectedMessages] = useState([])
  const [editing, setEditing] = useState(false)
  const [registered, setRegistered] = useState(false)
  const [lastViewed, setLastViewed] = useState(0)
  const isExpanded = useMemo(
    () => searchParams.get('conversation') === conversation?.sid ||
    (conversation == null && searchParams.get('conversation') === null),
    [searchParams, conversation]
  )

  useEffect(() => {
    if (conversation != null) {
      if (!registered) {
        // console.log('registering...')
        setRegistered(true)

        conversation.on('messageAdded', (message) => {
          // console.log('messageAdded', message.sid)
          setMessages(m => [
            ...m.filter(it => it.sid !== message.sid),
            message
          ])
        })
      }

      if (recipients.length === 0) {
        conversation.getParticipants().then(participants => {
          const phoneNumbers = participants
            .map(p => [p.identity, p.bindings.sms?.address ?? null])
            .flat()
            .filter(p => p != null)
            .filter(p => !p.includes('@'))
            .map(p => p.replace('+1', ''))
            .sort()

          setRecipients(phoneNumbers)
        })
      }
    }
  }, [conversation, recipients, registered])

  useEffect(() => {
    if (conversation != null && isExpanded) {
      // console.log('fetching...')
      conversation.getMessages().then(paginator => {
        setMessages(paginator.items)
      })
    }
  }, [conversation, isExpanded])

  useEffect(() => {
    if (isExpanded) {
      setLastViewed(messages.length)
    }
  }, [messages, isExpanded])

  const confirmDelete = useConfirm(
    'Delete conversation?',
    'Delete conversation permanently? This action cannot be undone.',
    () => { remove() }
  )
  const remove = useDelete(
    conversation != null ? `/api/conversation/${conversation.sid}` : null,
    () => { navigate(`/student/${personId}/guardian-messages`) }
  )

  const headerText = conversation == null
    ? '+ New Conversation'
    : conversation.friendlyName ?? recipients.map(formatNumber).join(', ')

  return (
    <RadExpandableSection
      headerText={headerText + (lastViewed < messages.length ? ' *' : '')}
      headerCounter={
        conversation != null &&
        conversation.friendlyName !== recipients.map(formatNumber).join(', ') &&
        recipients.map(formatNumber).join(', ')
      }
      headerTagOverride='h3'
      headerActions={
          conversation != null && (
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButtonDropdown
                items={[{
                  id: 'rename',
                  text: 'Rename Conversation',
                  iconName: 'edit'
                }, {
                  id: 'delete',
                  text: 'Delete Conversation',
                  iconName: 'remove'
                }]}
                onItemClick={({ detail }) => {
                  const { id } = detail
                  switch (id) {
                    case 'rename':
                      setEditing(true)
                      break
                    case 'delete':
                      confirmDelete()
                      break
                    default:
                      console.log(detail.id)
                  }
                }}
                variant='icon'
              />
            </RadSpaceBetween>
          )
      }
      expanded={isExpanded}
      onChange={({ detail }) => {
        setSearchParams(p => {
          if (!isExpanded && detail.expanded && conversation != null) {
            p.set('conversation', conversation.sid)
          } else {
            p.delete('key')
            p.delete('conversation')
            p.delete('message')
          }

          return p
        })
      }}
      variant='container'
    >
      <RadSpaceBetween size='s'>
        {
        isExpanded && conversation != null &&
          <RadBox float='right'>
            <LogSessionFromMessage
              personId={personId}
              conversationSid={conversation.sid}
              messages={messages}
              setSelecting={setSelecting}
              selectedMessages={selectedMessages}
              setSelectedMessages={setSelectedMessages}
            />
          </RadBox>
        }
        {
          conversation != null &&
            <MessageThread
              personId={personId}
              conversationSid={conversation.sid}
              messages={messages}
              selecting={selecting}
              selectedMessages={selectedMessages}
              setSelectedMessages={setSelectedMessages}
              searchParams={searchParams}
            />
        }
        <ConversationForm
          personId={personId}
          userInfo={userInfo}
          conversation={conversation}
          setSearchParams={setSearchParams}
        />
      </RadSpaceBetween>
      {
        conversation != null && editing &&
          <RenameConversationForm
            conversationSid={conversation.sid}
            friendlyName={conversation.friendlyName}
            setEditing={setEditing}
          />
      }
    </RadExpandableSection>
  )
}

function RenameConversationForm ({ conversationSid, friendlyName, setEditing }) {
  const [name, setName] = useState(friendlyName)
  const canSubmit = name !== undefined && name != null && name !== friendlyName && name !== ''

  const onDismiss = () => {
    setName(friendlyName)
    setEditing(false)
  }

  const submit = usePut(
    `/api/conversation/${conversationSid}`,
    { name },
    onDismiss
  )

  return (
    <RadModal
      onDismiss={onDismiss}
      visible
      header={
        <RadHeader variant='h2'>
          Rename {parseInt(friendlyName) ? formatNumber(friendlyName) : friendlyName}
        </RadHeader>
      }
      footer={
        <RadSpaceBetween direction='horizontal' size='xs'>
          <RadButton onClick={onDismiss}>
            Cancel
          </RadButton>
          <RadButton disabled={!canSubmit} onClick={submit} variant='primary'>Save</RadButton>
        </RadSpaceBetween>
      }
    >
      <RadSpaceBetween size='xs' direction='vertical'>
        <RadFormField label='Name' field='name' stretch>
          <RadInput
            value={name}
            onChange={({ detail }) => setName(detail.value)}
          />
        </RadFormField>
      </RadSpaceBetween>
    </RadModal>
  )
}
