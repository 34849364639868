import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as pluralize from 'pluralize'
import { toTitleCase } from 'titlecase'
import { AppContext } from './../App'
import { RadSideNavigation } from './RadSideNavigation'
import { RadBreadcrumbGroup } from './RadBreadcrumbGroup'
import AppLayout from '@cloudscape-design/components/app-layout'
import Flashbar from '@cloudscape-design/components/flashbar'

export function RadAppLayout ({ ...rest }) {
  const location = useLocation()
  const { warning, setWarning } = useContext(AppContext)
  const { info, setInfo } = useContext(AppContext)
  const { success, setSuccess } = useContext(AppContext)
  const { navigationOpen, setNavigationOpen } = useContext(AppContext)
  const notificationItems = []

  if (warning) {
    notificationItems.push({
      type: 'warning',
      onDismiss: () => setWarning(null),
      ...warning
    })
  }

  if (info) {
    notificationItems.push({
      type: 'info',
      onDismiss: () => setInfo(null),
      ...info
    })
  }

  if (success) {
    notificationItems.push({
      type: 'success',
      onDismiss: () => setSuccess(null),
      ...success
    })
  }

  function breadcrumbs () {
    const paths = location.pathname.split('/')
    const items = []
    let acc = ''

    items.push({ text: 'Home', href: '/' })
    paths.splice(0, 1)
    paths.forEach((path) => {
      acc += '/' + path
      const crumb = isNaN(path)
        ? toTitleCase(path.replace('-', ' '))
        : rest.name ?? path

      items.push({
        text: ['create', 'edit'].includes(path) || !isNaN(path) ? crumb : pluralize(crumb),
        href: acc
      })
    })

    return (<RadBreadcrumbGroup items={items} ariaLabel='Breadcrumbs' />)
  }

  if (!rest.breadcrumbs) {
    rest.breadcrumbs = breadcrumbs()
  }

  return (
    <AppLayout
      ariaLabels={{
        navigation: 'Navigation',
        navigationClose: 'Navigation close',
        navigationToggle: 'Navigation toggle',
        notifications: 'Notifications',
        tools: 'Tools',
        toolsClose: 'Tools close',
        toolsToggle: 'Tools toggle'
      }}
      {...rest}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      navigation={<RadSideNavigation setNavigationOpen={setNavigationOpen} />}
      notifications={
        <Flashbar
          items={notificationItems.map((n, i) => ({
            id: `message_${i + 1}`,
            type: n.type,
            header: n.code,
            content: n.message,
            loading: n.loading ?? false,
            onDismiss: n.onDismiss,
            dismissible: true,
            dismissLabel: 'Dismiss message'
          }))}
        />
      }
      toolsHide
    />
  )
}
