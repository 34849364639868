import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'

export function RadPermissionDenied ({ message }) {
  const navigate = useNavigate()

  function goHome () {
    navigate('/')
  }

  return (
    <RadBox
      textAlign='center'
      fontSize='display-l'
      padding={{ top: 'xxl' }}
    >
      <b>Permission Denied</b>
      <RadBox
        padding={{ bottom: 'xxl' }}
        color='text-body-secondary'
      >
        {message}
      </RadBox>
      <RadButton onClick={goHome}>Home</RadButton>
    </RadBox>
  )
}
