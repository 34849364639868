import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadCards } from '../common/RadCards'
import { RadAvatar } from '../common/RadAvatar'
import { RadLink } from '../common/RadLink'
import { RadBox } from '../common/RadBox'

export function RoleDetail () {
  const navigate = useNavigate()
  const { roleId } = useParams()
  const { data: role } = useGet(`/api/role/${roleId}`)
  const confirmDelete = useConfirm('Delete role?', 'Delete role permanently? This action cannot be undone.', () => { remove() })
  const remove = useDelete(`/api/role/${roleId}`, () => { navigate('/role') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (role) {
    return (
      <RadAppLayout
        name={role.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={role.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={
              (role.permissions.length > 0
                ? role.permissions.map((x) => x.name).join(', ')
                : role.name === 'Admin' ? '' : 'No permissions added to this role.') +
                ` Primary Program: ${role.primaryProgram?.name ?? '-'}` // Chipi don't love this
            }
          >
            {role.name}
          </RadHeader>
        }
        disableContentHeaderOverlap={role.users.length === 0}
        content={
          <RadCards
            ariaLabels={{
              itemSelectionLabel: (e, t) => `select ${t.name}`,
              selectionRoleLabel: 'Item selection'
            }}
            cardDefinition={{
              header: item => (
                <>
                  <RadAvatar
                    src={item.photoUrl}
                    size='large'
                    name={item.name}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/user/${item.id}`}>{item.name}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'email',
                  content: item => item.email
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={role.users}
            variant='full-page'
            empty={
              <RadBox textAlign='center' color='inherit' padding={{ top: 'l' }}>
                <RadBox
                  variant='p'
                  color='inherit'
                >
                  No users added to this role.
                </RadBox>
              </RadBox>
            }
          />
        }
      />
    )
  }
}
