import React from 'react'
import Spinner from '@cloudscape-design/components/spinner'

export function IsLoading ({ connected, loadingCount }) {
  if (!connected || loadingCount > 0) {
    return (
      <Spinner size='large' className='global-spinner' />
    )
  }
}
