import { useContext } from 'react'
import { AppContext } from './../App'

export function useDelete (uri, onSuccess) {
  const { user, setLoadingCount, reloadCounter, setReloadCounter, setError, setNotFound, setPermissionDenied, setConnected } = useContext(AppContext)

  async function del () {
    setLoadingCount(count => count + 1)
    setError(null)
    const token = await user.getIdToken()
    const response = await fetch(uri, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).catch(() => {
      return { status: 408 }
    })
    let json = {}
    let text = ''
    switch (response.status) {
      case 200:
        try { json = await response.json() } catch { }
        setReloadCounter(reloadCounter + 1)
        // Chipi: show snackbar?
        setError(null)
        setNotFound(false)
        setPermissionDenied(null)
        onSuccess(json)
        break
      case 400:
        setError({ code: response.statusText, validationErrors: json.validationErrors })
        break
      case 403:
        try { text = await response.text() } catch { }
        setError({ code: 'Permission Denied', message: text })
        break
      case 404:
        setError({ code: 'Not Found' }) // Chipi could be a bad uri, could be the think your trying to update is missing
        break
      case 408:
        setConnected(false)
        setError({ code: 'Network Error', message: 'Please check your internet connection.' })
        break
      case 422:
        try { text = await response.text() } catch { text = 'Unable to delete.' }
        setError({ code: 'Unprocessable', message: text })
        break
      case 500:
        try { json = await response.json() } catch { response.disconnected = true }

        // dev server responds with 500 when it can't proxy the request
        if (response.disconnected) {
          setConnected(false)
          setError({ code: 'Network Error', message: 'Please check your internet connection.' })
          return
        }

        if (json.name === 'SequelizeForeignKeyConstraintError') {
          setError({ code: 'Unprocessable', message: 'Unable to delete. Resource is in use.' })
          // Chipi - id rather get a 422 from the server
        } else {
          setError({ code: 'Error', message: 'Please contact administrator.' })
        }
        break
      default:
        try { text = await response.text() } catch { }
        setError({ code: response.statusText, message: text })
    }
    setLoadingCount(count => count - 1)
  }

  return del
}
