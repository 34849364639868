import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDate, humanReadableId, isValidDate } from '../common/utilities'

export function CaseList () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [championFilterText, setChampionFilterText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const schoolYear = searchParams.get('schoolYear') ?? ''
  const programId = searchParams.get('programId') ?? ''
  const schoolId = searchParams.get('schoolId') ?? ''
  const grade = searchParams.get('grade') ?? ''
  const from = searchParams.get('from') ?? ''
  const to = searchParams.get('to') ?? ''
  const championId = searchParams.get('championId') ?? ''
  const ownerId = searchParams.get('ownerId') ?? ''
  const { data: cases, count } = useGet(
    `/api/case?search=${searchText}` +
    `&schoolYear=${schoolYear}` +
    `&from=${isValidDate(from) ? from : ''}` +
    `&to=${isValidDate(to) ? to : ''}` +
    `&programId=${programId}` +
    `&schoolId=${schoolId}` +
    `&grade=${grade}` +
    `&ownerId=${ownerId}` +
    `&championId=${championId}` +
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const { data: userInfo } = useGet('/api/user/current')
  const { data: schoolYearOptions } = useGet('/api/option/school-year')
  const { data: programOptions } = useGet('/api/option/program?any=true')
  const { data: schoolOptions } = useGet('/api/option/school?any=true')
  const { data: gradeOptions } = useGet('/api/option/grade?any=true')
  const { data: championOptions } = useGet(`/api/person?type=person&options=true&any=true&search=${championFilterText}`)
  const { data: ownerOptions } = useGet(`/api/option/user?anyOwner=true&required=false&search=${championFilterText}&limit=20&id=${ownerId}&includeDeleted=true`)

  if (
    cases != null &&
    schoolYearOptions != null &&
    schoolOptions != null &&
    gradeOptions != null &&
    // championOptions != null &&
    // ownerOptions != null &&
    programOptions != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + Number(count).toLocaleString() + ')'}
          >
            Focus List Enrollments
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/enrollment/${humanReadableId(item.id)}`}>{humanReadableId(item.id)}</RadLink>,
              sections: [
                {
                  id: 'person',
                  header: 'Student',
                  content: item => <RadLink href={`/student/${item.person.id}`}>{`${item.person?.firstName} ${item.person.preferredName ? '"' + item.person.preferredName + '" ' : ''} ${item.person?.lastName}`}</RadLink>
                },
                {
                  id: 'program',
                  header: 'Program',
                  content: item => item.program.name
                },
                {
                  id: 'indicator',
                  header: 'Indicator',
                  content: item => item.service.name
                },
                {
                  id: 'opened',
                  header: 'Opened',
                  content: item => item.openedAt != null ? formatDate(item.openedAt) : '-'
                },
                {
                  id: 'status',
                  header: 'Status',
                  content: item => item.status?.name ?? '-'
                },
                {
                  id: 'owner',
                  header: 'Owner',
                  content: item => <RadLink href={`/user/${item.owner.id}`}>{item.owner.name}</RadLink>
                },
                {
                  id: 'champions',
                  header: 'Champions',
                  content: item =>
                    <RadSpaceBetween direction='horizontal' size='xxs'>
                      {
                        item.champions.length > 0 && item.champions.map(champion =>
                          <RadLink key={champion.id} href={`/person/${champion.id}`}>
                            {champion.name}
                          </RadLink>
                        )
                      }
                      {item.champions.length === 0 && <div key={0}>-</div>}
                    </RadSpaceBetween>
                },
                {
                  id: 'closedAt',
                  header: 'Closed Date',
                  content: item => item.closedAt != null ? formatDate(item.closedAt) : '-'
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={cases}
            variant='full-page'
            filter={
              <div>
                <RadBox padding={{ bottom: 's' }}>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, s: 6, m: 8 } },
                      { colspan: { default: 12, s: 6, m: 4 } }
                    ]}
                  >
                    <RadTextFilter
                      filteringPlaceholder='Search'
                      filteringAriaLabel='Search sessions'
                      filteringText={filteringText}
                      onChange={({ detail }) => setFilteringText(detail.filteringText)}
                      onDelayedChange={({ detail }) => {
                        setSearchText(detail.filteringText)
                        setCurrentPageIndex(1)
                        searchParams.set('page', 1)
                        if (detail.filteringText) {
                          searchParams.set('search', detail.filteringText)
                        } else {
                          searchParams.delete('search')
                        }
                        setSearchParams(searchParams)
                      }}
                    />
                    <RadBox float='right'>
                      <RadPagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={Math.ceil(count / pageLength)}
                        onChange={({ detail }) => {
                          searchParams.set('page', detail.currentPageIndex)
                          setSearchParams(searchParams)
                          setCurrentPageIndex(detail.currentPageIndex)
                        }}
                        ariaLabels={{
                          nextPageLabel: 'Next page',
                          previousPageLabel: 'Previous page',
                          pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                        }}
                      />
                    </RadBox>
                  </RadGrid>
                </RadBox>
                <RadSpaceBetween direction='horizontal' size='s'>
                  <RadSelect
                    selectedOption={schoolYearOptions.find((x) => x.value === (schoolYear)) ?? schoolYearOptions[0]}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('schoolYear', detail.selectedOption.value)
                      } else {
                        searchParams.delete('schoolYear')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={schoolYearOptions ?? []}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select school year'
                  />
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadBox padding={{ top: 'xxs' }}>From:</RadBox>
                      <RadDatePicker
                        onChange={({ detail }) => {
                          if (detail.value !== '' && isValidDate(detail.value)) {
                            searchParams.set('from', detail.value)
                          } else {
                            searchParams.delete('from')
                          }
                          setSearchParams(searchParams)
                        }}
                        value={from}
                        placeholder='From any date'
                        openCalendarAriaLabel={selectedDate =>
                          'Choose from date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      />
                    </RadSpaceBetween>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadBox padding={{ top: 'xxs' }}>To:</RadBox>
                      <RadDatePicker
                        onChange={({ detail }) => {
                          if (detail.value !== '' && isValidDate(detail.value)) {
                            searchParams.set('to', detail.value)
                          } else {
                            searchParams.delete('to')
                          }
                          setSearchParams(searchParams)
                        }}
                        value={to}
                        placeholder='To any date'
                        openCalendarAriaLabel={selectedDate =>
                          'Choose to date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                      />
                    </RadSpaceBetween>
                  </RadSpaceBetween>
                  <RadSelect
                    selectedOption={programOptions.find((x) => x.value === programId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('programId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('programId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={programOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select program'
                  />
                  <RadSelect
                    selectedOption={schoolOptions.find((x) => x.value === schoolId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('schoolId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('schoolId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={schoolOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select school'
                  />
                  <RadSelect
                    selectedOption={gradeOptions.find((x) => x.value === grade)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('grade', detail.selectedOption.value)
                      } else {
                        searchParams.delete('grade')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={gradeOptions}
                    filteringType='auto'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select grade'
                  />
                  <RadSelect
                    selectedOption={ownerOptions?.find((x) => x.value === ownerId)}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        searchParams.set('ownerId', detail.selectedOption.value)
                      } else {
                        searchParams.delete('ownerId')
                      }
                      setSearchParams(searchParams)
                    }}
                    options={ownerOptions ?? []}
                    filteringType='manual'
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Select owner'
                    onLoadItems={({ detail }) => {
                      setChampionFilterText(detail.filteringText)
                    }}
                    empty={championFilterText ? 'No matches found' : null}
                  />
                  {(userInfo?.roles ?? []).some(x => x.name === 'Admin' || [2, 3].includes(x.primaryProgramId)) &&
                    <RadSelect
                      selectedOption={championOptions?.find((x) => x.value === (searchParams.get('championId') ?? ''))}
                      onChange={({ detail }) => {
                        if (detail.selectedOption.value !== '') {
                          searchParams.set('championId', detail.selectedOption.value)
                        } else {
                          searchParams.delete('championId')
                        }
                        setSearchParams(searchParams)
                      }}
                      options={championOptions ?? []}
                      filteringType='manual'
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Select champion'
                      onLoadItems={({ detail }) => {
                        setChampionFilterText(detail.filteringText)
                      }}
                      empty={championFilterText ? 'No matches found' : null}
                    />}
                </RadSpaceBetween>
              </div>
            }
            empty={
              <RadBox
                margin={{ vertical: 'xs' }}
                textAlign='center'
                color='inherit'
              >
                <RadSpaceBetween size='m'>
                  <b>No enrollments found</b>
                </RadSpaceBetween>
              </RadBox>
            }
          />
        }
      />
    )
  }
}
