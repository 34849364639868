import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useIsMobile } from '../hooks/useIsMobile'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadGrid } from '../common/RadGrid'
import { RadForm } from '../common/RadForm'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadTextarea } from '../common/RadTextarea'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { stateList } from '../common/stateList'

export function OrganizationEdit () {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { organizationId } = useParams()
  const { data: organization } = useGet(organizationId ? `/api/organization/${organizationId}` : null)
  const [formValues, setFormValues] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const { data: parentOptions } = useGet(`/api/option/organization?required=true&${organizationId !== undefined ? '&exclude=' + organizationId : ''}`)
  const { data: typeOptions } = useGet('/api/option/type?entity=organization')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}`)
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { users: [] }
    setFormValues(organization ?? defaultFormValues)
  }, [organization])

  const create = usePost('/api/organization', formValues, (resp) => { navigate(`/organization/${resp.id}`) })
  const update = usePut(`/api/organization/${organizationId}`, formValues, (resp) => { navigate(`/organization/${organizationId}`) })

  async function saveChanges () {
    if (organizationId) { update() } else { create() }
  }

  async function cancel () {
    if (organizationId) {
      navigate(`/organization/${organizationId}`)
    } else {
      navigate('/organization')
    }
  }

  if (formValues && parentOptions && typeOptions) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit Organization' : 'New Organization'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Parent Organization' field='parentId'>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.parentId ? parentOptions.find(x => x.value === formValues.parentId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, parentId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, parentId: null })
                          }
                        }}
                        options={parentOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Type' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Phone' field='phone'>
                      <RadInput
                        placeholder='Enter phone number'
                        value={formValues.phone ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Address
                    </RadHeader>
                  }
                >
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 12, xxs: 6 } }
                    ]}
                  >
                    <RadFormField label='Address' field='line1'>
                      <RadInput
                        placeholder='Enter line 1'
                        value={formValues.address?.line1 ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField label={isMobile ? null : '.'} field='line2'>
                      <RadInput
                        placeholder='Enter line 2'
                        value={formValues.address?.line2 ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xxs: 6 } },
                      { colspan: { default: 6, xxs: 3 } },
                      { colspan: { default: 6, xxs: 3 } }
                    ]}
                  >
                    <RadFormField field='city'>
                      <RadInput
                        placeholder='Enter city'
                        value={formValues.address?.city ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='state'>
                      <RadSelect
                        filteringType='auto'
                        placeholder='Choose a state'
                        options={stateListOptions}
                        selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
                        onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
                      />
                    </RadFormField>
                    <RadFormField field='zip'>
                      <RadInput
                        placeholder='Enter ZIP code'
                        inputMode='numeric'
                        value={formValues.address?.zip ?? ''}
                        onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
                      />
                    </RadFormField>
                  </RadGrid>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Caseworkers
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const users = formValues.users
                      users.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, users })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const users = [...formValues.users]
                      users.splice(itemIndex, 1)
                      setFormValues({ ...formValues, users })
                    }}
                    items={formValues.users}
                    addButtonText='Add caseworker'
                    definition={[
                      {
                        label: 'Caseworkers',
                        control: item =>
                          <RadFormField field={`users.${item.id || item.uuid}.id`} stretch>
                            <RadSelect
                              selectedOption={item.id ? { value: item.id.toString(), label: item.name } : null}
                              onChange={({ detail }) => {
                                const users = formValues.users
                                const user = formValues.users.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                user.id = parseInt(detail.selectedOption.value)
                                user.name = detail.selectedOption.label
                                setFormValues({ ...formValues, users })
                              }}
                              filteringType='manual'
                              onLoadItems={({ detail }) => {
                                setUserFilterText(detail.filteringText)
                              }}
                              options={userOptions ?? []}
                              enteredTextLabel={value => value}
                              selectedAriaLabel='Selected'
                              placeholder='Choose a user'
                              empty='No matches found'
                            />
                          </RadFormField>
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No caseworks associated with this organization.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
