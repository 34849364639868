import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadForm } from '../common/RadForm'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'

export function RoleEdit () {
  const navigate = useNavigate()
  const { roleId } = useParams()
  const [formValues, setFormValues] = useState()
  const [userFilterText, setUserFilterText] = useState('')
  const { data: role } = useGet(roleId ? `/api/role/${roleId}` : '')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}&required=true`)
  const { data: permissionOptions } = useGet('/api/option/permission')
  const { data: programOptions } = useGet('/api/option/program?required=true')
  const create = usePost('/api/role', formValues, (resp) => { navigate(`/role/${resp.id}`) })
  const update = usePut(`/api/role/${roleId}`, formValues, (resp) => { navigate(`/role/${roleId}`) })

  useEffect(() => {
    setFormValues(role ?? { users: [], permissions: [] })
  }, [role])

  async function saveChanges () {
    if (roleId) { update() } else { create() }
  }

  async function cancel () {
    if (roleId) {
      navigate(`/role/${roleId}`)
    } else {
      navigate('/role')
    }
  }

  if (formValues != null && permissionOptions != null && programOptions != null) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>{formValues.id ? 'Edit' : 'New'} Role</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h1'>Details</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter name'
                        disabled={formValues.isProtected}
                        value={formValues.name ?? ''}
                        onChange={event => setFormValues({ ...formValues, name: event.detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Primary Program' field='primaryProgramId'>
                      <RadSelect
                        selectedOption={formValues.primaryProgramId ? programOptions.find((o) => o.value === formValues.primaryProgramId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, primaryProgramId: detail.selectedOption.value })
                        }}
                        disabled={formValues.isProtected}
                        options={programOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a program'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h1'>Users</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const users = formValues.users
                        users.push({ uuid: uuidv4() })
                        setFormValues({ ...formValues, users })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const users = [...formValues.users]
                        users.splice(itemIndex, 1)
                        setFormValues({ ...formValues, users })
                      }}
                      items={formValues.users}
                      addButtonText='Add new user'
                      definition={[
                        {
                          label: 'User',
                          control: item => {
                            const filteredOptions = (userOptions ?? []).filter((x) => !formValues.users.map((y) => y.id?.toString()).includes(x.value))
                            const selectedOption = item.id ? { value: item.id.toString(), label: item.name } : null
                            return (
                              <RadFormField field={`users.${item.id || item.uuid}.id`}>
                                <RadSelect
                                  selectedOption={selectedOption}
                                  onChange={({ detail }) => {
                                    const users = formValues.users
                                    const user = formValues.users.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    user.id = parseInt(detail.selectedOption.value)
                                    user.name = detail.selectedOption.label
                                    setFormValues({ ...formValues, users })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a user'
                                  filteringType='manual'
                                  onLoadItems={({ detail }) => {
                                    setUserFilterText(detail.filteringText)
                                  }}
                                  empty={userFilterText ? 'No matches found' : null}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No users added to this role.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
                {
                  formValues.name !== 'Admin' &&
                    <RadContainer
                      header={
                        <RadHeader variant='h1'>Permissions</RadHeader>
                      }
                    >
                      <RadAttributeEditor
                        onAddButtonClick={() => {
                          const permissions = formValues.permissions
                          permissions.push({ uuid: uuidv4() })
                          setFormValues({ ...formValues, permissions })
                        }}
                        onRemoveButtonClick={({
                          detail: { itemIndex }
                        }) => {
                          const permissions = [...formValues.permissions]
                          permissions.splice(itemIndex, 1)
                          setFormValues({ ...formValues, permissions })
                        }}
                        items={formValues.permissions}
                        addButtonText='Add new permission'
                        definition={[
                          {
                            label: 'Permission',
                            control: item => {
                              const filteredOptions = permissionOptions.filter((x) => !formValues.permissions.map((y) => y.id?.toString()).includes(x.value))
                              return (
                                <RadFormField field={`permissions.${item.id || item.uuid}.id`}>
                                  <RadSelect
                                    filteringType='auto'
                                    selectedOption={permissionOptions.find(x => x.value === item.id?.toString())}
                                    onChange={({ detail }) => {
                                      const permissions = formValues.permissions
                                      const permission = formValues.permissions.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                      permission.id = parseInt(detail.selectedOption.value)
                                      permission.name = detail.selectedOption.label
                                      permission.lastName = detail.selectedOption.label.split(' ')[1]
                                      setFormValues({ ...formValues, permissions })
                                    }}
                                    options={filteredOptions}
                                    enteredTextLabel={value => value}
                                    selectedAriaLabel='Selected'
                                    placeholder='Choose a permission'
                                    empty='No matches found'
                                  />
                                </RadFormField>
                              )
                            }
                          }
                        ]}
                        removeButtonText='Remove'
                        empty='No permissions added to this role.'
                      />
                    </RadContainer>
                }
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
