import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadLink } from '../common/RadLink'
import { RadPropertyFilter } from '../common/RadPropertyFilter'
import { RadPagination } from '../common/RadPagination'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadPieChart } from '../common/RadPieChart'
import { RadButton } from '../common/RadButton'
import { formatDate, humanReadableId } from '../common/utilities'

const tokenize = (propertyKey, filterText) => [...new Set(filterText.split(','))]
  .filter(s => s.split('|').length === 2)
  .map(s => {
    const [operator, value] = s.split('|')
    return { propertyKey, operator, value }
  })

const getPropFilterQuery = (searchText, filterTexts) => ({
  operation: 'and',
  tokens: [
    { operator: ':', value: searchText },
    ...Object.keys(filterTexts).map(key => tokenize(key, filterTexts[key])).flat()
  ].filter(t => t.value !== '')
})

export function Dashboard () {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: stats } = useGet('/api/dashboard/stats')

  const filterData = [
    ['status', useGet('/api/option/type?entity=status')],
    ['indicator', useGet('/api/option/service')],
    ['school', useGet('/api/option/school')],
    ['grade', useGet('/api/option/grade')]
  ]
  const [filterTexts, setFilterTexts] = useState({
    ...filterData.map(([key]) => ({ [key]: searchParams.get(key) ?? '' })).reduce((acc, curr) => {
      return { ...acc, ...curr }
    })
  })
  const [propFilterQuery, setPropFilterQuery] = useState(
    getPropFilterQuery(searchText, filterTexts)
  )

  const filterParams = Object.keys(filterTexts).map(key => `${key}=${filterTexts[key]}`).join('&')
  const { data: casesRawData, count } = useGet(
    `/api/dashboard/cases?search=${searchText}&${filterParams}` +
    `&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`
  )
  const cases = casesRawData?.rowData
  const graphData = casesRawData?.graphData

  const onQueryChange = ({ tokens, operation }) => {
    const searchWords = tokens
      .filter(t => t.propertyKey === undefined)
      .map(t => t.value)
      .join(' ')
      .split(' ')
      .filter(x => x !== '')

    if (searchWords.length > 0) {
      searchParams.set('search', searchWords.join(' '))
    } else {
      searchParams.delete('search')
    }

    // ['[operator]|[value]']
    const mapFilterText = (propertyKey) => [...new Set(tokens
      .filter(t => t.propertyKey === propertyKey)
      .map(t => `${t.operator}|${t.value}`)
    )]

    const updatedFilterTexts = {}
    for (const key of Object.keys(filterTexts)) {
      const texts = mapFilterText(key)
      updatedFilterTexts[key] = texts.join(',')

      if (texts.length > 0) {
        searchParams.set(key, texts.join(','))
      } else {
        searchParams.delete(key)
      }
    }

    setFilterTexts(updatedFilterTexts)
    setSearchText(searchWords.join(' '))
    setPropFilterQuery(getPropFilterQuery(searchWords.join(' '), updatedFilterTexts))

    searchParams.set('page', 1)
    setSearchParams(searchParams)
    setCurrentPageIndex(1)
  }

  const filterOptionsLoaded = filterData.every(([key, { data }]) => data != null)
  if (cases != null && filterOptionsLoaded) {
    const filterOptions = filterData
      .map(([key, { data }]) => data.filter(o => o.value !== '').map(o => ({
        propertyKey: key,
        ...o
      })))
      .flat()
    const filterProperties = filterData.map(([key]) => ({
      key,
      operators: ['=', '!='].map(operator => ({
        operator,
        format: (value) => filterData
          .find(([k]) => k === key)[1].data
          .find(o => o.value === value)?.label ?? value
      })),
      propertyLabel: key.charAt(0).toUpperCase() + key.slice(1),
      groupValuesLabel: key.charAt(0).toUpperCase() + key.slice(1) + ' values'
    }))

    return (
      <RadAppLayout
        name='Dashboard'
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/enrollment/create')}>Create Focus List Enrollment</RadButton>
                <RadButton onClick={() => navigate('/session/create')}>Create Session</RadButton>
              </RadSpaceBetween>
            }
          >
            Dashboard
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h1'>
                  <RadBox padding={{ bottom: 'xxl' }}>
                    <img src='/DISCO_logo.svg' alt='DISCO Logo' />
                  </RadBox>
                  Sessions
                </RadHeader>
              }
              variant='stacked'
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <RadBox variant='awsui-key-label'>
                  Count
                  <RadBox fontSize='display-l' fontWeight='bold' color='text-status-info'>
                    {stats?.sessionCount ?? ''}
                  </RadBox>
                </RadBox>
                <RadBox variant='awsui-key-label'>
                  Dosage
                  <RadBox fontSize='display-l' fontWeight='bold' color='text-status-info'>
                    {stats?.totalDosage ?? ''}
                  </RadBox>
                </RadBox>
                <RadBox variant='awsui-key-label'>
                  Students
                  <RadBox fontSize='display-l' fontWeight='bold' color='text-status-info'>
                    {stats?.studentCount ?? ''}
                  </RadBox>
                </RadBox>
              </RadColumnLayout>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader
                  variant='h1'
                >
                  Focus List Enrollments
                </RadHeader>
              }
              variant='stacked'
            >
              <RadSpaceBetween size='l'>
                <RadPropertyFilter
                  collectionName='Focus List Enrollments'
                  onChange={({ detail }) => onQueryChange(detail)}
                  query={propFilterQuery}
                  countText={`${count} enrollment${count === '1' ? '' : 's'}`}
                  filteringOptions={filterOptions}
                  filteringProperties={filterProperties}
                  hideOperations
                  expandToViewport
                  tokenLimit={4}
                />
                <RadPieChart
                  data={graphData}
                  variant='donut'
                  segmentDescription={(datum, sum) =>
                    `${datum.value} enrollments, ${((datum.value / sum) * 100).toFixed(0)}%`}
                  i18nStrings={{
                    detailsValue: 'Value',
                    detailsPercentage: 'Percentage',
                    filterLabel: 'Filter displayed data',
                    filterPlaceholder: 'Filter data',
                    filterSelectedAriaLabel: 'selected',
                    detailPopoverDismissAriaLabel: 'Dismiss',
                    legendAriaLabel: 'Legend',
                    chartAriaRoleDescription: 'pie chart',
                    segmentAriaRoleDescription: 'segment'
                  }}
                  ariaDescription='Pie chart showing how many cases per indicator.'
                  ariaLabel='Pie chart'
                  innerMetricDescription='total'
                  innerMetricValue={count}
                  hideFilter
                  hideLegend
                  size={isMobile ? 'medium' : 'large'}
                  empty={
                    <RadBox textAlign='center' color='inherit'>
                      No focus list enrollments
                    </RadBox>
                  }
                  noMatch={
                    <RadBox textAlign='center' color='inherit'>
                      No focus list enrollments
                    </RadBox>
                  }
                />
                <RadTable
                  wrapLines
                  columnDefinitions={[
                    {
                      id: 'service',
                      header: 'Indicator',
                      cell: e => e.service.name
                    },
                    {
                      id: 'person',
                      header: 'Student',
                      cell: e => <RadLink href={`/student/${e.person.id}`}>{`${e.person?.firstName} ${e.person.preferredName ? '"' + e.person.preferredName + '" ' : ''} ${e.person?.lastName}`}</RadLink>
                    },
                    {
                      id: 'number',
                      header: 'FL Enrollment',
                      cell: e => <RadLink href={`/enrollment/${humanReadableId(e.id)}`}>{humanReadableId(e.id)}</RadLink>
                    },
                    {
                      id: 'totalDosage',
                      header: 'Total Dosage',
                      cell: e => e.totalDosage
                    },
                    {
                      id: 'totalSessions',
                      header: 'Total Sessions',
                      cell: e => e.totalSessions
                    },
                    {
                      id: 'lastSessionDate',
                      header: 'Last Session Date',
                      cell: e => e.lastSessionDate ? formatDate(e.lastSessionDate) : '-'
                    },
                    {
                      id: 'status',
                      header: 'Status',
                      cell: e => e.status
                    },
                    {
                      id: 'school',
                      header: 'School',
                      cell: e => e.person.school?.name ?? '-'
                    },
                    {
                      id: 'grade',
                      header: 'Grade',
                      cell: e => e.person.school?.grade ?? '-'
                    }
                  ]}
                  items={cases}
                  variant='embedded'
                  pagination={
                    <RadPagination
                      currentPageIndex={currentPageIndex}
                      pagesCount={Math.ceil(count / pageLength)}
                      onChange={({ detail }) => {
                        searchParams.set('page', detail.currentPageIndex)
                        setSearchParams(searchParams)
                        setCurrentPageIndex(detail.currentPageIndex)
                      }}
                      ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                      }}
                    />
                  }
                />
              </RadSpaceBetween>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
